import React from 'react'

export default function LandingOutroSection({
  isDesktop,
}: {
  isDesktop: boolean
}): React.JSX.Element {
  return (
    <section className={`h-[100vh] outro-bg ${isDesktop ? 'pc' : 'mo'}`}>
      <article className={'landing-container flex justify-center items-center flex-col'}>
        <img
          className={'w-[104px] tablet:w-fit none-drag'}
          src={require(`../../../assets/logo.png`)}
          alt=""
        />
        <div className={'text-white text-[28px] tablet:text-[48px] font-bold text-center'}>
          가장 빠른 <br className={'tablet:hidden'} /> 전자제품 중고거래, <br />{' '}
          <span className={'text-[#29A5FF]'}> 퀵셀</span>로 시작하세요
        </div>
        <div
          className={
            'flex mt-[80px] tablet:mt-[120px] gap-[16px] tablet:gap-[32px] flex-col tablet:flex-row'
          }
        >
          <img
            role="presentation"
            className={'w-[240px] tablet:w-[320px] none-drag cursor-pointer'}
            src={require(`../../../assets/img/landing/outro/google_play.png`)}
            alt=""
            onClick={() => window.open(process.env.REACT_APP_GOOGLE_PLAY_STORE_URL, '_target')}
          />
          <img
            role="presentation"
            className={'w-[240px] tablet:w-[320px] none-drag cursor-pointer'}
            src={require(`../../../assets/img/landing/outro/app_store.png`)}
            alt=""
            onClick={() => window.open(process.env.REACT_APP_APP_STORE_URL, '_target')}
          />
        </div>
      </article>
    </section>
  )
}
