import * as React from 'react'
import { Dispatch, SetStateAction } from 'react'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import { TShareIcon } from '../Header'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

type TShareModalProps = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  shareIcons: TShareIcon[]
}
export default function FullScreenDialog({ open, setOpen, shareIcons = [] }: TShareModalProps) {
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div className={'flex justify-between'}>
      <Dialog
        fullScreen
        open={open}
        classes={{
          root: 'bottom-0 top-[initial] px-0',
          paper: 'rounded-t-[30px] pt-[10px] px-[10px] pb-[38px]',
        }}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <div className={'relative bg-white text-black shadow-none pb-[28px] px-0'}>
          <div className={'flex items-center justify-between px-[16px]'}>
            <h3 className={'text-[18px] text-[#333333] font-[600] tracking-[-0.5px]'}>공유하기</h3>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className={'flex justify-center'}>
          <div className={'flex gap-[32px] text-[12px]'}>
            {shareIcons.map((item) => (
              <div
                role="presentation"
                key={item.id}
                className={`flex flex-col items-center ${item.onClick ? 'cursor-pointer' : ''}`}
                onClick={item.onClick}
              >
                <img className={'mb-[8px] h-[50px]'} src={item.imgSrcMo} alt={item.id} />
                {item.text}
              </div>
            ))}
          </div>
        </div>
      </Dialog>
    </div>
  )
}
