import commonRequest from '../../request/common'
import { TResponse } from '../../request/types'
import { TReqSnsLogin, TReqSnsLoginByCode, TResListEnumType, TResSnsLogin } from './types' // 1P 상품 매입 목록

// 1P 상품 매입 목록
export const getItemOpenStatusCd = () => {
  return commonRequest<null, TResponse<TResListEnumType[]>>({
    url: `/v1/allows/enum/ItemOpenStatusCd`,
    method: 'get',
  })
}

export const postLogin = (data: TReqSnsLogin) => {
  return commonRequest<TReqSnsLogin, TResponse<TResSnsLogin>>({
    url: '/v2/allows/member-user/web/sns/code/login',
    method: 'post',
    data,
  })
}

export const postLoginByCode = (data: TReqSnsLoginByCode) => {
  return commonRequest<TReqSnsLoginByCode, TResponse<TResSnsLogin>>({
    url: '/v2/allows/member-user/web/sns/code/login',
    method: 'post',
    data,
  })
}
