import React from 'react'
import LandingHeader from './components/Header'
import LandingMainBannerSection from './components/MainBanner.section'
import LandingProcessSection from './components/Process.section'
import LandingIntroSection from './components/Intro.section'
import LandingOutroSection from './components/Outro.section'
import LandingCompareSection from './components/Compare.section'
import LandingBenefitSection from './components/Benefit.section'
import useWindowResize from '../../utils/hooks/windowResize'
import CommonFooter from '../../components/Footer'

export default function PageHome(): React.JSX.Element {
  const { isDesktop } = useWindowResize()

  return (
    <main className={'relative w-full min-w-[340px] safe-area-bottom-padding'}>
      <LandingHeader isDesktop={isDesktop} />
      <LandingMainBannerSection isDesktop={isDesktop} />
      <LandingIntroSection isDesktop={isDesktop} />
      <LandingBenefitSection isDesktop={isDesktop} />
      <LandingCompareSection isDesktop={isDesktop} />
      <LandingProcessSection />
      <LandingOutroSection isDesktop={isDesktop} />
      <div
        className={
          'landing-container flex justify-center items-center flex-col bg-[#f5f5f5] p-0 max-w-none'
        }
      >
        <CommonFooter />
      </div>
    </main>
  )
}
