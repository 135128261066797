export function validUrl(url: string) {
  const pattern =
    /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/g
  if (!url) {
    return 'URL을 입력해주세요.'
  } else if (!pattern.test(url)) {
    return '적합하지 않은 URL입니다.'
  }
  return true
}
