import React from 'react'

export type TIntroImg = {
  id: string
  imgSrcPc: any
  imgSrcMo: any
}

export default function LandingIntroSection({
  isDesktop,
}: {
  isDesktop: boolean
}): React.JSX.Element {
  const IntroItems: TIntroImg[] = [
    {
      id: 'example1',
      imgSrcPc: require('../../../assets/img/landing/intro/intro_pc_01.png'),
      imgSrcMo: require('../../../assets/img/landing/intro/intro_mo_01.png'),
    },
    {
      id: 'example2',
      imgSrcPc: require('../../../assets/img/landing/intro/intro_pc_02.png'),
      imgSrcMo: require('../../../assets/img/landing/intro/intro_mo_02.png'),
    },
    {
      id: 'example3',
      imgSrcPc: require('../../../assets/img/landing/intro/intro_pc_03.png'),
      imgSrcMo: require('../../../assets/img/landing/intro/intro_mo_03.png'),
    },
  ]

  return (
    <section className={'h-full bg-[#F5FBFF] py-[90px] tablet:py-[160px]'}>
      <article className={'landing-container flex justify-center items-center flex-col'}>
        <div className={'text-[28px] tablet:text-[48px] font-bold transition-all '}>
          혹시 이런 분이신가요?
        </div>
        <div
          className={
            isDesktop
              ? 'tablet:flex mt-[80px] w-full justify-center gap-[40px]'
              : 'flex flex-col mt-[40px] gap-[16px]'
          }
        >
          {IntroItems.map((item) => (
            <div
              key={item.id}
              className={[
                isDesktop ? 'max-w-[374px]' : 'min-w-[312px]',
                'drop-shadow-[6px_8px_14px_rgba(0,0,0,0.15)]',
              ].join(' ')}
            >
              <img
                className={`none-drag ${isDesktop ? '' : 'h-[160px]'}`}
                src={isDesktop ? item.imgSrcPc : item.imgSrcMo}
                alt={item.id}
              />
            </div>
          ))}
        </div>
      </article>
    </section>
  )
}
