import React from 'react'
import { isAndroid, isIOS, isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'

export default function PageStoreRedirect(): React.JSX.Element {
  const navigate = useNavigate()
  const handleRedirect = (type) => {
    if (type === 'ios') {
      window.open(process.env.REACT_APP_APP_STORE_URL, '_target')
    } else if (type === 'aos') {
      window.open(process.env.REACT_APP_GOOGLE_PLAY_STORE_URL, '_target')
    }
  }

  const handleClickMain = () => {
    navigate('/')
  }

  if (isMobile) {
    if (isIOS) {
      handleRedirect('ios')
    } else if (isAndroid) {
      handleRedirect('aos')
    }
  }
  return (
    <div className={'w-screen h-screen bg-[#394150] flex flex-col justify-center items-center'}>
      <div className={'w-[120px] tablet:w-[240px]'}>
        <img src={require('../assets/logo.png')} alt={'quicksell logo'} />
      </div>
      <div
        className={
          'text-white text-[28px] tablet:text-[48px] font-bold text-center tablet:text-left'
        }
      >
        가장 빠른 <br className={'tablet:hidden'} /> 전자제품 중고거래, <br />{' '}
        <span className={'text-[#29A5FF]'}> 퀵셀</span>로 시작하세요
      </div>
      <div className={'flex gap-[8px] tablet:gap-[16px] flex-col tablet:flex-row mt-[80px]'}>
        {isMobile ? (
          <>
            {isIOS && (
              <div
                role="presentation"
                className={'w-[180px] tablet:w-[240px] cursor-pointer'}
                onClick={() => handleRedirect('ios')}
              >
                <img src={require(`../assets/img/landing/outro/app_store.png`)} alt="app store" />
              </div>
            )}
            {isAndroid && (
              <div
                role="presentation"
                className={'w-[180px] tablet:w-[240px] cursor-pointer'}
                onClick={() => handleRedirect('aos')}
              >
                <img
                  src={require(`../assets/img/landing/outro/google_play.png`)}
                  alt="google play"
                />
              </div>
            )}
          </>
        ) : (
          <>
            <div
              role="presentation"
              className={'w-[180px] tablet:w-[240px] cursor-pointer'}
              onClick={() => handleRedirect('ios')}
            >
              <img src={require(`../assets/img/landing/outro/app_store.png`)} alt="app store" />
            </div>
            <div
              role="presentation"
              className={'w-[180px] tablet:w-[240px] cursor-pointer'}
              onClick={() => handleRedirect('aos')}
            >
              <img src={require(`../assets/img/landing/outro/google_play.png`)} alt="google play" />
            </div>
          </>
        )}
        <button
          type={'button'}
          className={
            'border border-[#505765] w-[180px] h-[54px] !bg-[#505765] text-white rounded-md px-4 py-2 text-[16px] tablet:w-[240px] tablet:h-[72px] tablet:text-[20px]'
          }
          onClick={handleClickMain}
        >
          메인으로 가기
        </button>
      </div>
    </div>
  )
}
