import deepCopy from './copy'

export function changePhoneFormat(data: string) {
  return data.replace(/([0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3')
}

export const changeNullParameterDelete = (params: any): any => {
  const returnParma = deepCopy(params)
  Object.keys(returnParma).forEach((key) => {
    if (returnParma[key] === null || returnParma[key] === '' || returnParma[key] === 0) {
      delete returnParma[key]
    }
  })
  return returnParma
}

export const replaceNumberWordFilter = (num: any): string => {
  let result = ''
  if (num) {
    const num_text = String(num)
    const nums = ['', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구']
    const units = ['', '십', '백', '천']
    const carrys = ['', '만', '억', '조', '경']
    for (let i = 0; i < num_text.length; i++) {
      let str = ''
      const num_ko = nums[Number(num_text.charAt(num_text.length - (i + 1)))]
      if (num_ko !== '') {
        str += num_ko + units[i % 4]
      }
      if (i > 0 && i % 4 === 0) {
        const c = Math.ceil(i / 4)
        const is_not_before_carry =
          Number(num_text.substring(num_text.length - 4 * (c + 1), num_text.length - 4 * c)) > 0
        if (num_text.length <= c * 2 || is_not_before_carry) {
          str += carrys[c]
          const is_carry = Number(num_text.substring(num_text.length - 4 * c, num_text.length)) > 0
          if (is_carry) {
            str += ' '
          }
        }
      }
      result = str + result
    }
    if (result === '') {
      result = '0'
    }
    result = result + '원'
  }
  return result
}
