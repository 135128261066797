import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Autoplay, Controller } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'

export default function LandingCompareSection({
  isDesktop,
}: {
  isDesktop: boolean
}): React.JSX.Element {
  const swiperOptions = {
    allowTouchMove: false,
    centeredSlides: true,
    slidesPerView: 1,
    spaceBetween: 0,
    loop: false,
    modules: [Autoplay, Controller],
  }

  const sectionRef = useRef(null)
  const firstSwiperRef = useRef(null)
  const thirdSwiperRef = useRef(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [firstSwiper, setFirstSwiper] = useState(null)
  const [secondSwiper, setSecondSwiper] = useState(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [thirdSwiper, setThirdSwiper] = useState(null)

  const initSwiper = useCallback(async ([entry], observer) => {
    if (!entry.isIntersecting) {
      return
    }
    firstSwiperRef.current?.slideTo(0)
    thirdSwiperRef.current?.slideTo(0)
    observer.unobserve(entry.target) // 함수가 실행될 때, 관찰을 끝내기.
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver(initSwiper, { threshold: 0.1 })
    observer.observe(sectionRef.current)
    return () => {
      observer.disconnect()
    }
  }, [initSwiper])

  useEffect(() => {
    firstSwiperRef.current?.slideTo(0)
    thirdSwiperRef.current?.slideTo(0)
  }, [isDesktop])

  return (
    <section ref={sectionRef} className={'bg-[#29A5FF]'}>
      <article
        className={
          'landing-container flex flex-col justify-center items-center pt-[80px] tablet:pt-[160px] gap-[40px] tablet:gap-[80px]'
        }
      >
        <h2
          className={
            'text-white text-[28px] tablet:text-[48px] font-[700] leading-[1.4] tracking-[-0.07px] text-center transition-all'
          }
        >
          퀵셀은 이름 그대로
          <br />
          얼마나 빠를까요?
        </h2>
        <div
          className={
            'w-full max-w-[1200px] flex flex-col tablet:flex-row items-center tablet:items-start justify-between'
          }
        >
          <div
            className={
              'relative min-w-[240px] tablet:max-w-[478px] flex justify-end w-[calc(40%)] bg-[#29A5FF]'
            }
          >
            <div className={'absolute top-[-4px] left-0 right-0 bottom-0 z-[999]'}>
              <img
                className={'none-drag'}
                src={require(
                  `../../../assets/img/landing/benefit/frame_${isDesktop ? 'pc' : 'mo'}.png`,
                )}
                alt={'01'}
              />
            </div>
            <Swiper
              className={'w-[calc(100%_-_2px)] flex-shrink-0'}
              {...swiperOptions}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              onInit={(swiper) => {
                firstSwiperRef.current = swiper
              }}
              onSwiper={setFirstSwiper}
              controller={{ control: secondSwiper }}
            >
              <SwiperSlide className={'bg-black'}>
                <img
                  className={'none-drag'}
                  src={require(
                    `../../../assets/img/landing/benefit/quicksell_${
                      isDesktop ? 'pc' : 'mo'
                    }_01.png`,
                  )}
                  alt={'01'}
                />
              </SwiperSlide>
              <SwiperSlide className={'bg-black'}>
                <img
                  className={'none-drag'}
                  src={require(
                    `../../../assets/img/landing/benefit/quicksell_${
                      isDesktop ? 'pc' : 'mo'
                    }_02.png`,
                  )}
                  alt={'02'}
                />
              </SwiperSlide>
              <SwiperSlide className={'bg-black'}>
                <img
                  className={'none-drag'}
                  src={require(
                    `../../../assets/img/landing/benefit/quicksell_${
                      isDesktop ? 'pc' : 'mo'
                    }_03.png`,
                  )}
                  alt={'03'}
                />
              </SwiperSlide>
            </Swiper>
            <div
              className={
                'absolute left-0 right-0 bottom-0 h-[120px] tablet:h-[300px] z-[1000] bg-gradient-to-b from-[transparent] to-[#29A5FF]'
              }
            >
              <span
                className={
                  'absolute bottom-[32px] tablet:bottom-[80px] left-1/2 translate-x-[-50%] text-white text-[14px] tablet:text-[32px] font-[700] leading-[1.4] tracking-[-0.035px] transition-all'
                }
              >
                퀵셀
              </span>
            </div>
          </div>
          <div
            className={
              'mt-[12px] tablet:mt-[80px] mb-[42px] text-center text-white text-[20px] tablet:text-[40px] font-[700] leading-[1.4] tracking-[-0.05px] w-[calc(100%)] tablet:w-[calc(20%)]'
            }
          >
            <Swiper
              className={'w-full h-[54px]'}
              {...swiperOptions}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              onInit={(swiper) => {
                thirdSwiperRef.current = swiper
              }}
              direction={'vertical'}
              onSwiper={setThirdSwiper}
            >
              <SwiperSlide>
                <span className={'transition-all'}>1초 후</span>
              </SwiperSlide>
              <SwiperSlide>
                <span className={'transition-all'}>12시간 후</span>
              </SwiperSlide>
              <SwiperSlide>
                <span className={'transition-all'}>3일 후</span>
              </SwiperSlide>
            </Swiper>
          </div>
          <div
            className={
              'relative min-w-[240px] tablet:max-w-[478px] flex justify-end w-[calc(40%)] bg-[#29A5FF]'
            }
          >
            <div className={'absolute top-[-4px] left-0 right-0 bottom-0 z-[999]'}>
              <img
                className={'none-drag'}
                src={require(
                  `../../../assets/img/landing/benefit/frame_${isDesktop ? 'pc' : 'mo'}.png`,
                )}
                alt={'01'}
              />
            </div>
            <Swiper
              className={'w-[calc(100%_-_2px)] flex-shrink-0'}
              {...swiperOptions}
              onSwiper={setSecondSwiper}
            >
              <SwiperSlide className={'w-full bg-black'}>
                <img
                  className={'none-drag'}
                  src={require(
                    `../../../assets/img/landing/benefit/other_${isDesktop ? 'pc' : 'mo'}_01.png`,
                  )}
                  alt={'01'}
                />
              </SwiperSlide>
              <SwiperSlide className={'w-full bg-black'}>
                <img
                  className={'none-drag'}
                  src={require(
                    `../../../assets/img/landing/benefit/other_${isDesktop ? 'pc' : 'mo'}_02.png`,
                  )}
                  alt={'02'}
                />
              </SwiperSlide>
              <SwiperSlide className={'w-full bg-black'}>
                <img
                  className={'none-drag'}
                  src={require(
                    `../../../assets/img/landing/benefit/other_${isDesktop ? 'pc' : 'mo'}_03.png`,
                  )}
                  alt={'03'}
                />
              </SwiperSlide>
            </Swiper>
            <div
              className={
                'absolute left-0 right-0 bottom-0 h-[140px] tablet:h-[300px] z-[1000] bg-gradient-to-b from-[transparent] to-[#29A5FF] from-0%'
              }
            >
              <span
                className={
                  'absolute bottom-[32px] tablet:bottom-[80px] left-1/2 translate-x-[-50%] text-white text-[14px] tablet:text-[32px] font-[700] leading-[1.4] tracking-[-0.035px] transition-all'
                }
              >
                기존 중고 거래
              </span>
            </div>
          </div>
        </div>
      </article>
    </section>
  )
}
