import apiRequest from '../../request/axios'
import { TResponse } from '../../request/types'
import {
  TReqBundleServiceDeals,
  TReqMarketPrice,
  TReqPostBundleServiceDeals,
  TReqProductExtract,
  TResListTypeForBundleServiceDeals,
  TResMarketPrice,
  TResProductExtract,
} from './types'

// 개봉 상태 코드

// 1P 상품 매입 목록
export const getBundleServiceDeals = (params: TReqBundleServiceDeals) => {
  return apiRequest<TReqBundleServiceDeals, TResponse<TResListTypeForBundleServiceDeals>>({
    url: `/v1/allows/bundles/service-deals`,
    method: 'get',
    params,
  })
}

// 1P 상품 매입 임시 신청
export const postBundleServiceDealsTemporary = (data: TReqPostBundleServiceDeals) => {
  return apiRequest<TReqPostBundleServiceDeals, null>({
    url: `/v1/auth/bundles/service-deals/temporary`,
    method: 'post',
    data,
  })
}

// 1P 상품 매입 임시 신청
export const getBundleServiceDealsTemporaryCount = () => {
  return apiRequest<null, TResponse<number>>({
    url: `/v1/auth/bundles/service-deals/temporary-count`,
    method: 'get',
  })
}

// 번개 맞은 당근 나라 URL 분석 요청
export const getProductsExtract = (params: TReqProductExtract) => {
  return apiRequest<TReqProductExtract, TResponse<TResProductExtract>>({
    url: `/v1/auth/products/extract`,
    method: 'get',
    params,
    timeout: 1000 * 60 * 5, // 5분 ( 1초 * 60 * 5 )
  })
}

// 매입 아이템 가격 조회
export const getServiceItemSettingsMarketPrice = (params: TReqMarketPrice) => {
  return apiRequest<TReqMarketPrice, TResponse<TResMarketPrice>>({
    url: `/v1/auth/service-item-settings/market-price`,
    method: 'get',
    params,
  })
}
