import React, { useCallback, useEffect, useState } from 'react'
import { QInput } from '../../../../components/Input'
import { QButton } from '../../../../components/Button'
import PromotionSellSubTitle from '../components/SubTitle'
import PromotionSellTitle from '../components/Title'
import PromotionSellHeader from '../components/Header'
import { useController, useForm } from 'react-hook-form'
import { validUrl } from '../../../../utils/validate'
import { usePopupStore } from '../../../../store/popup'
import { getProductsExtract } from '../../../../apis/api/promotion'
import { TReqProductExtract } from '../../../../apis/api/promotion/types'
import { usePromotionSellStore } from '../../../../store/promotionSell'
import { useNavigate } from 'react-router-dom'
import { toast } from '../../../../components/Toast'

export default function PagePromotionSellUrlImport(): React.JSX.Element {
  const promotionSellStore = usePromotionSellStore()
  const navigate = useNavigate()
  const [isVisible, setVisible] = useState(false)

  const handleClickHelp = () => {
    setVisible(!isVisible)
  }

  const defaultValues: TReqProductExtract = {
    url: '',
  }

  const methods = useForm<TReqProductExtract>({
    defaultValues: defaultValues,
    mode: 'all',
  })

  const {
    field: urlForm,
    fieldState: urlFieldState,
    formState: urlFormState,
  } = useController({
    name: 'url',
    control: methods.control,
    rules: { validate: validUrl },
  })

  const popupStore = usePopupStore()

  const handleSubmit = useCallback(() => {
    methods.trigger()
    if (urlFormState.isValid) {
      popupStore.setLoading({
        text: () => (
          <div className={'text-center'}>
            자동으로 정보를 불러오기 위해 <br /> 판매글을 분석 중이에요.
          </div>
        ),
      })
      popupStore.setLoadingVisible(true)

      // url call
      getProductsExtract({ url: encodeURIComponent(methods.getValues('url')) })
        .then((res) => {
          promotionSellStore.setData('importUrl', methods.getValues('url'))
          promotionSellStore.setData('extractData', res.context)
          popupStore.setLoadingVisible(false)

          if (res.context?.matchedData) {
            navigate('/promotion/sell/attribute-check', { replace: false })
          } else {
            toast('해당 판매글에서 제품 정보와 일치하는 제품을 찾을 수 없습니다.').red()
          }
        })
        .catch((err) => {
          // 30000번대 에러 발생시 존재하지 않는 URL 입니다. 처리
          if (err.returnCode === 10001) {
            methods.setError('url', { type: '', message: '존재하지 않는 URL입니다.' })
            methods.setFocus('url')
          } else {
            toast('판매글을 확인 할 수 없습니다. 잠시후 다시 시도해주세요.').red()
          }
          promotionSellStore.resetData('attributes')
          popupStore.setLoadingVisible(false)
        })
    }
  }, [promotionSellStore, methods, navigate, popupStore, urlFormState.isValid])

  useEffect(
    () => {
      if (promotionSellStore.importUrl) {
        methods.setValue('url', promotionSellStore.importUrl)
        methods.reset(methods.getValues())
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <>
      <PromotionSellHeader />
      <div className={'px-[32px] py-[48px] inline-flex flex-col justify-between flex-1'}>
        <div>
          <PromotionSellTitle>
            등록했던 판매글의
            <br />
            URL을 복붙해주세요
          </PromotionSellTitle>
          <PromotionSellSubTitle addClass={'mt-[8px]'}>
            힘들게 다시 글 쓰지 않아도 등록돼요
          </PromotionSellSubTitle>
        </div>
        <div className={'mt-[42px] flex-1 inline-flex flex-col justify-between'}>
          <div>
            <QInput
              ref={urlForm.ref}
              value={urlForm.value}
              onChange={urlForm.onChange}
              theme={'dark'}
              placeholder={'URL 복사 후 여기에 붙여넣기'}
              isPasteCheck={true}
            />
            {/*조건에 따라 표기되는 문구 3개*/}
            {urlFieldState?.error && (
              <p className={'text-[#FC5454] text-[12px] font-medium mt-[8px]'}>
                {urlFieldState.error.message}
              </p>
            )}
          </div>
          <div
            className={`relative w-full text-center transition-all ${
              isVisible ? 'mt-[91px] mb-[179px]' : 'my-[40px]'
            }`}
          >
            <button
              className={
                'w-full text-[#6C727F] text-[14px] flex items-center justify-center cursor-pointer'
              }
              onClick={handleClickHelp}
            >
              URL 복사 어떻게 하나요?
              <img
                className={`w-[16px] h-[16px] ml-[4px] transition-all ${
                  isVisible ? '' : 'rotate-180'
                }`}
                src={require(`../../../../assets/icon/arrow-up.png`)}
                alt={isVisible ? 'open arrow' : 'close arrow'}
              />
            </button>
            <div
              className={[
                'w-full text-white absolute top-[39px] max-w-[260px] left-1/2 translate-x-[-50%] leading-[1.5] transition-opacity',
                isVisible ? 'opacity-100' : 'h-0 opacity-0',
              ].join(' ')}
            >
              판매글 오른쪽 상단 공유 아이콘 터치 후 URL 복사(링크 복사, 복사, Copy) 버튼을
              눌러주세요.
            </div>
          </div>

          <QButton
            addClass={'mt-[32px] inline-flex items-center justify-center'}
            onClick={handleSubmit}
            disabled={!methods.formState.isValid}
          >
            <span className={'text-[18px] font-semibold'}>붙여넣기 완료</span>
          </QButton>
        </div>
      </div>
    </>
  )
}
