import { useCallback, useEffect } from 'react'
import { toast } from '../../components/Toast'
import { postLogin } from '../../apis/api/common'
import { setToken } from '../token'
import { usePopupStore } from '../../store/popup'

type TUseKakaoProps = {
  loginSuccessCallback?: () => void
  loginFailureCallback?: () => void
}

export default function useKaKao({ loginSuccessCallback, loginFailureCallback }: TUseKakaoProps) {
  const popupStore = usePopupStore()

  const initiate = useCallback(() => {
    const { Kakao } = window
    if (!Kakao.isInitialized()) {
      Kakao.init(process.env.REACT_APP_KAKAO_KEY)
    }
  }, [])

  const onError = useCallback((err) => {
    console.warn(`This component threw an error (in '${err.target.outerHTML}'):`)
  }, [])

  const kakaoScriptInit = useCallback(() => {
    const scriptId = 'kakao_login'
    const isExist = !!document.getElementById(scriptId)
    if (!isExist) {
      const script = document.createElement('script')
      script.src = 'https://developers.kakao.com/sdk/js/kakao.min.js'
      script.onload = () => initiate()
      script.onerror = (error) => onError(error)
      script.id = scriptId
      document.body.appendChild(script)
    }
  }, [initiate, onError])

  const onSuccessKakao = useCallback(
    (data) => {
      popupStore.setLoadingVisible(true)
      postLogin({ accessToken: data.access_token, loginTypeCd: 'KAKAO' })
        .then(({ context }) => {
          setToken({
            accessToken: context.tokenInfo.access_token,
            refreshToken: context.tokenInfo.refresh_token,
          })
          popupStore.setLoadingVisible(false)
          if (loginSuccessCallback && typeof loginSuccessCallback === 'function') {
            loginSuccessCallback()
          }
        })
        .catch((err) => {
          console.error('postLogin err :: ', err)
          popupStore.setLoadingVisible(false)
          if (loginFailureCallback && typeof loginFailureCallback === 'function') {
            loginFailureCallback()
          }
        })
    },
    [loginFailureCallback, loginSuccessCallback, popupStore],
  )
  const onFailureKakao = useCallback((data) => {
    console.error('kakao failure :: ', data)
    toast('현재 카카오 로그인 서비스를 이용할 수 없습니다. 잠시 후 다시 시도해주세요.').red()
  }, [])

  const handleClickKakaoLogin = useCallback(() => {
    const { Kakao } = window
    Kakao.Auth.login({
      success: (authObj) => onSuccessKakao(authObj),
      fail: (err) => onFailureKakao(err),
    })
  }, [onFailureKakao, onSuccessKakao])

  const handleClickKakaoShare = useCallback(() => {
    const { Kakao } = window
    Kakao.Share.sendDefault({
      objectType: 'feed',
      content: {
        title: '퀵셀 - 빠른 중고 전자제품 판매',
        description:
          '간편한 시세 조회, 사진만 찍으면 입금, 비대면 방문 수거. 퀵셀에서 새로운 중고 거래를 경험해보세요.',
        /* TODO. 이미지 추가 필요 */
        imageUrl: '',
        link: {
          mobileWebUrl: `${window.location.href}`,
          webUrl: `${window.location.href}`,
        },
      },
    })
  }, [])

  useEffect(() => {
    kakaoScriptInit()
  }, [kakaoScriptInit])
  return {
    handleClickKakaoLogin,
    handleClickKakaoShare,
  }
}
