import React, { useCallback, useEffect, useState } from 'react'
import PromotionSellTitle from '../components/Title'
import PromotionSellHeader from '../components/Header'
import { QButton } from '../../../../components/Button'
import PromotionSellSubTitle from '../components/SubTitle'
import { usePopupStore } from '../../../../store/popup'
import { usePromotionSellStore } from '../../../../store/promotionSell'
import { useNavigate } from 'react-router-dom'
import {
  getBundleServiceDealsTemporaryCount,
  postBundleServiceDealsTemporary,
} from '../../../../apis/api/promotion'
import {
  TReqMarketPriceItemAttributeValues,
  TReqPostBundleServiceDeals,
} from '../../../../apis/api/promotion/types'
import { isAndroid, isIOS, isMobile } from 'react-device-detect'
import PromotionSelResultQrDialog from './components/QrDialog'

export default function PagePromotionSellResult(): React.JSX.Element {
  const promotionSellStore = usePromotionSellStore()
  const popupStore = usePopupStore()
  const navigate = useNavigate()
  const [isSell, setIsSell] = useState<boolean>(true)

  const handleClickMoveUrlImport = useCallback(() => {
    promotionSellStore.resetData()
    navigate('/promotion/sell/url-import?&isReplaceBack=true', { replace: false })
  }, [navigate, promotionSellStore])

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const handleOpenDialog = useCallback(() => {
    setIsOpen(true)
  }, [])

  const handleMoveStore = useCallback(() => {
    if (isIOS) {
      window.location.href = process.env.REACT_APP_APP_STORE_URL
    } else if (isAndroid) {
      window.location.href = process.env.REACT_APP_GOOGLE_PLAY_STORE_URL
    }
  }, [])

  const handleSubmit = useCallback(
    async () => {
      const { context: isCount } = await getBundleServiceDealsTemporaryCount()
      if (isCount > 0) {
        popupStore.setAlert({
          title: '먼저 진행하던 상품이 있어요',
          isOutsideClose: false,
          contents: () => (
            <div className={'px-[16px]'}>
              한 사람당 한 상품만 판매 대기 가능하며 퀵셀 앱에서 나머지 정보 입력 후 바로 입금받을
              수 있어요.
            </div>
          ),
          maxWidth: '280px',
          closeText: '판매하러 가기',
          handleClose: (close) => {
            if (isMobile) {
              handleMoveStore()
            } else {
              handleOpenDialog()
            }
            close()
          },
        })
        popupStore.setAlertVisible(true)
      } else {
        const valueList = promotionSellStore.extractData.valueList
        const selectedValueIds = promotionSellStore.optionList.map(
          (item) => item.selectedAttributeValueId,
        )
        const selectedValueList: TReqMarketPriceItemAttributeValues[] = []
        selectedValueIds.forEach((selectedValueId) => {
          valueList.some((vItem) => {
            if (vItem.attributeValueId === selectedValueId) {
              selectedValueList.push({
                attributeId: vItem.attributeId,
                attributeValueId: vItem.attributeValueId,
              })
              return true
            }
            return false
          })
        })
        const createRequest: TReqPostBundleServiceDeals = {
          bundle: {
            name: promotionSellStore.extractData.parsedInfo.title,
            items: [
              {
                productId: promotionSellStore.extractData.matchedData.productId,
                openStatusCd: promotionSellStore.openStatusCd,
                attributeValueList: selectedValueList,
                price: promotionSellStore.marketPrice.marketPrice,
              },
            ],
            imageList: promotionSellStore.extractData.parsedInfo.imageData.map(
              (item) => item.imageUrl,
            ),
            detailContent: promotionSellStore.extractData.parsedInfo.description,
          },
        }
        postBundleServiceDealsTemporary(createRequest)
          .then(() => {
            if (isMobile) {
              handleMoveStore()
            } else {
              handleOpenDialog()
            }
          })
          .catch((err) => {
            console.error(err)
          })
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [promotionSellStore, popupStore],
  )

  useEffect(
    () => {
      setIsSell(
        promotionSellStore?.marketPrice &&
          promotionSellStore?.marketPrice.isAvailable &&
          promotionSellStore?.marketPrice.marketPrice > 0,
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <>
      <div className={'flex flex-col justify-between min-h-screen promotion-bg'}>
        <PromotionSellHeader />
        <div className={'px-[32px] py-[48px] inline-flex flex-col justify-between flex-1'}>
          {isSell ? (
            <>
              <div>
                <PromotionSellTitle>
                  지금 퀵셀 앱에서
                  <br />
                  <span className={'text-[#29A5FF]'}>
                    {Number(promotionSellStore?.marketPrice?.marketPrice).toLocaleString()}원
                  </span>
                  에<br />
                  즉시 판매 가능해요
                </PromotionSellTitle>
                <PromotionSellSubTitle addClass={'mt-[8px]'}>
                  <>몇개만 더 입력하면 바로 입금돼요</>
                </PromotionSellSubTitle>
                <div
                  className={
                    'w-full bg-[#272f3e] mt-[48px] rounded-[8px] overflow-hidden relative text-white'
                  }
                >
                  <ul
                    className={
                      'text-white py-[32px] px-[18px] text-[14px] flex flex-col gap-[18px]'
                    }
                  >
                    <li>· &nbsp; 입금받을 계좌</li>
                    <li>· &nbsp; 수거할 장소</li>
                    <li>
                      <span>· &nbsp; 카드 정보</span>

                      <div
                        className={
                          'text-[12px] text-[#A0A6B1] mt-[4px] pl-[15px] pr-[22px] break-keep leading-[1.5]'
                        }
                      >
                        너무 다른 상품이 올까봐 필요하지만 트집을 잡기 위한 용도는 절대 아니니
                        안심하세요!
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <QButton
                  addClass={'mt-[32px] inline-flex items-center justify-center'}
                  onClick={handleSubmit}
                >
                  <span className={'text-[18px] font-semibold'}>퀵셀 앱에서 즉시 입금받기</span>
                </QButton>
              </div>
            </>
          ) : (
            <>
              <div>
                <PromotionSellTitle>
                  <div className={'max-w-[300px] break-keep'}>퀵셀이 매입할 수 없는 상품이에요</div>
                </PromotionSellTitle>
                <PromotionSellSubTitle addClass={'mt-[8px]'}>
                  상품 상태에 따라 판매 금액이 정해지며
                  <br />
                  해당 상품의 가격이 많이 낮아 매입이 어려워요
                  <br />
                  다른 상품으로 다시 진행해주세요
                </PromotionSellSubTitle>
              </div>
              <div>
                <QButton
                  addClass={'mt-[32px] inline-flex items-center justify-center'}
                  onClick={handleClickMoveUrlImport}
                >
                  <span className={'text-[18px] font-semibold'}>다른 상품 판매 금액 확인하기</span>
                </QButton>
              </div>
            </>
          )}
        </div>
      </div>
      <PromotionSelResultQrDialog open={isOpen} setOpen={setIsOpen} />
    </>
  )
}
