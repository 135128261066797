'use client'

import React, { ChangeEvent, forwardRef, memo, PropsWithChildren, Ref, useCallback } from 'react'

type TRadioProps = {
  type?: 'default' | 'button'
  onChange?: (value: any) => void
  defaultValue: any
  value: any
  name: string
  size?: 'small' | 'middle' | 'large'
  disabled?: boolean
  color?: 'primary'
  addClass?: string
}

const sizeClass = {
  small: 'w-[20px] h-[20px]',
  middle: 'w-[20px] h-[20px]',
  large: 'w-[20px] h-[20px]',
}

const colorClass = {
  primary: '',
}

const textSizeClass = {
  small: 'text-[16px]',
  middle: 'text-[16px]',
  large: 'text-[16px]',
}

const wrapperClass = 'form-control w-full '
const disabledTextClass =
  'group-[.is-disabled]:text-gray-300 group-[.is-disabled]:cursor-not-allowed'

function RadioMain(
  { children, onChange, ...props }: PropsWithChildren<TRadioProps>,
  ref: Ref<HTMLInputElement>,
): React.JSX.Element {
  const {
    type = 'default',
    addClass,
    defaultValue,
    value,
    disabled = false,
    size = 'middle',
    name,
    color = 'primary',
  } = props

  const radioClass = [
    'relative appearance-none rounded-full border-[2px] border-solid border-[#4D5562] bg-[#394150]',
    "before:pointer-events-none before:absolute before:!h-[10px] before:!w-[10px] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:content-['']",
    "after:absolute after:z-[1] after:block after:!h-[10px] after:!w-[10px] after:rounded-full after:content-['']",
    "checked:bg-transparent checked:border-[#29A5FF] checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[12px] checked:after:w-[12px] checked:after:rounded-full checked:after:border-[#29A5FF] checked:after:bg-[#29A5FF] checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] focus:shadow-none focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-[#29A5FF] checked:focus:before:scale-100 checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s]",
    'group-[.is-error]/form:border-red-500',
    sizeClass[size],
    colorClass[color],
  ].filter((className) => !!className)

  const buttonClass = ['hidden', sizeClass[size], colorClass[color]].filter(
    (className) => !!className,
  )

  const handleChangeRadio = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e.target.value)
      }
    },
    [onChange],
  )

  return (
    <div
      className={`group min-w-[110px] ${wrapperClass}${disabled && 'is-disabled'} ${
        addClass || ''
      }`}
    >
      <label
        className={`w-full inline-flex cursor-pointer justify-start items-center ${textSizeClass[size]} leading-none ${disabledTextClass}`}
      >
        <input
          ref={ref}
          type="radio"
          checked={value + '' === defaultValue + ''}
          className={type === 'button' ? buttonClass.join(' ') : radioClass.join(' ')}
          disabled={disabled}
          name={name}
          value={value}
          onChange={handleChangeRadio}
        />
        {type === 'default' ? (
          <span className={value + '' === defaultValue + '' ? 'text-white' : 'text-[#4D5562]'}>
            {children}
          </span>
        ) : (
          <div
            className={`${
              value + '' === defaultValue + ''
                ? 'text-white bg-[#29A5FF]'
                : 'text-[#6C727F] bg-[#394150]'
            } w-full text-center group-first-of-type:rounded-l-[12px] group-last-of-type:rounded-r-[12px]`}
          >
            {children}
          </div>
        )}
      </label>
    </div>
  )
}

function RadioLabel({ children }: PropsWithChildren): React.JSX.Element {
  return <span className={`ml-[8px] p-1 block w-[max-content]`}>{children}</span>
}

function ButtonLabel({ children }: PropsWithChildren): React.JSX.Element {
  return <span className={`py-[15px] px-[16px] block`}>{children}</span>
}

export const QRadio = Object.assign(memo(forwardRef(RadioMain)), {
  RadioLabel: memo(RadioLabel),
  ButtonLabel: memo(ButtonLabel),
})
