import React, { useCallback } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

type TPromotionSellHeaderProps = {
  isVisible?: boolean
}

const prevPageObj = {
  '/promotion/sell': null,
  '/promotion/sell/url-import': '/promotion/sell',
  '/promotion/sell/attribute-check': '/promotion/url-import',
  '/promotion/sell/result': '/promotion/attribute-check',
}

export default function PromotionSellHeader({
  isVisible = true,
}: TPromotionSellHeaderProps): React.JSX.Element {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const handleClickHistoryBack = useCallback(() => {
    const isReplaceBack = Boolean(searchParams.get('isReplaceBack'))
    if (isReplaceBack) {
      navigate(prevPageObj[location.pathname])
    } else {
      navigate(-1)
    }
  }, [navigate, location.pathname, searchParams])

  return (
    <div
      className={
        'py-[14px] px-[32px] flex items-center justify-between bg-[#171c27] z-10 sticky top-0'
      }
    >
      <img
        className={'w-[75px] h-[28px]'}
        src={require(`../../../../assets/logo_promotion.png`)}
        alt="promotion logo"
      />
      {isVisible && (
        <div
          role={'presentation'}
          className={'text-white text-[14px] flex items-center cursor-pointer'}
          onClick={handleClickHistoryBack}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            className="w-4 h-4 mr-1"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
          </svg>
          이전
        </div>
      )}
    </div>
  )
}
