'use client'
import { PropsWithChildren } from 'react'

export type TButtonProps = {
  type?: 'button' | 'submit'
  size?: 'small' | 'middle' | 'large'
  disabled?: boolean
  color?: 'primary' | 'yellow' | 'dark-gray'
  onClick?: () => void
  addClass?: string
}

const sizeClass = {
  small: 'min-h-[44px] text-[15px] px-[18px] py-[14px] rounded-[10px]',
  middle: 'min-h-[64px] text-[18px] px-[12px] py-[18px] rounded-[16px]',
  large: 'min-h-[64px] text-[18px] px-[12px] py-[18px]',
}
const colorClass = {
  primary: '!bg-[#29A5FF] text-[#fff]',
  yellow: '!bg-[#FEE500] text-[#fff]',
  'dark-gray': '!bg-[#394150] text-[#fff]',
}

function ButtonIndex({ children, ...props }: PropsWithChildren<TButtonProps>): React.JSX.Element {
  const {
    type = 'button',
    size = 'middle',
    disabled,
    color = 'primary',
    onClick,
    addClass = '',
  } = props

  const btnClassNames = [
    'no-animation border-none w-full',
    sizeClass[size],
    colorClass[color],
    addClass,
    'disabled:!bg-gray-500 disabled:text-gray-400 disabled:cursor-not-allowed disabled:pointer-events-auto',
  ].filter((className) => !!className)

  return (
    <button type={type} className={btnClassNames.join(' ')} disabled={disabled} onClick={onClick}>
      {children}
    </button>
  )
}

export const QButton = ButtonIndex
