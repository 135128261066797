import React, { useCallback, useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import PreviewDialog from '../../../../components/PreviewDialog'

type TPromotionSellImageCarousel = {
  list?: any[]
}

export default function PromotionSellImageCarousel({
  list,
}: TPromotionSellImageCarousel): React.JSX.Element {
  const settings = {
    className: 'slider variable-width',
    dots: false,
    infinite: false,
    slidesToShow: 1.5,
    variableWidth: true,
    swipeToSlide: true,
    arrows: false,
  }

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [imageIndex, setImageIndex] = useState<number>(null)
  const handleOpenDialog = useCallback(
    (idx: number) => {
      setImageIndex(idx)
      setIsOpen(!isOpen)
    },
    [isOpen],
  )

  return (
    <>
      <Slider {...settings}>
        {list.map((item, idx) => (
          <div key={idx} className={`p-[4px]`}>
            <div
              role="presentation"
              className={`${item} w-[120px] h-[120px] rounded-[8px] overflow-hidden cursor-pointer`}
              onClick={() => handleOpenDialog(idx)}
            >
              <img className={'w-full h-auto'} src={item} alt={'test'} />
            </div>
          </div>
        ))}
      </Slider>
      <PreviewDialog
        list={list}
        targetPage={imageIndex}
        open={isOpen}
        setImageIndex={setImageIndex}
        setOpen={setIsOpen}
      />
    </>
  )
}
