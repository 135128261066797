import React, { PropsWithChildren } from 'react'

type TPromotionSellSubTitle = {
  addClass?: string
}

export default function PromotionSellSubTitle({
  children,
  ...props
}: PropsWithChildren<TPromotionSellSubTitle>): React.JSX.Element {
  return (
    <p
      className={[
        'text-[#6C727F] font-medium fold:text-[12px] leading-[1.5] transition-all',
        props.addClass,
      ].join(' ')}
    >
      {children}
    </p>
  )
}
