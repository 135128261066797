import * as React from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

interface Props {
  count: string
  contents: string[]
}

export default function QToggleButton({ count, contents }: Props) {
  const [alignment, setAlignment] = React.useState<string | null>('left')

  const handleAlignment = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
    setAlignment(newAlignment)
  }

  return (
    <div>
      {count === '2' && (
        <ToggleButtonGroup
          value={alignment}
          exclusive
          onChange={handleAlignment}
          aria-label="text alignment"
        >
          <ToggleButton value="left" aria-label="left aligned">
            {contents[0]}
          </ToggleButton>
          <ToggleButton value="right" aria-label="right aligned">
            {contents[1]}
          </ToggleButton>
        </ToggleButtonGroup>
      )}
      {count === '3' && (
        <ToggleButtonGroup
          value={alignment}
          exclusive
          onChange={handleAlignment}
          aria-label="text alignment"
        >
          <ToggleButton value="left" aria-label="left aligned">
            {contents[0]}
          </ToggleButton>
          <ToggleButton value="center" aria-label="centered">
            {contents[1]}
          </ToggleButton>
          <ToggleButton value="right" aria-label="right aligned">
            {contents[2]}
          </ToggleButton>
        </ToggleButtonGroup>
      )}
    </div>
  )
}
