import React from 'react'
import { QRadio } from '../../../../../components/Radio'
import { useController } from 'react-hook-form'

type TPromotionSellAttributeCheckButtonGroupFormProps = {
  idx: number
  list: any[]
  title: string
  defaultValue?: any
  methods?: any
}

export function PromotionSellAttributeCheckButtonGroupForm({
  idx,
  list,
  title,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  defaultValue,
  methods,
}: TPromotionSellAttributeCheckButtonGroupFormProps): React.JSX.Element {
  /*
   * 노출조건 (attributeList > typeCd : DEPRECIATION 가 아닌것만 노출)
   * name  (attributeList)  : (attributeId)_(attirubuteId)
   * value (valueList)      : attributeValueId
   * title                  : name
   * label                  : value
   */
  const { field, fieldState } = useController({
    name: `optionList.${idx}`,
    control: methods.control,
    rules: {
      validate: (value) => {
        return value?.selectedAttributeValueId ? true : `${title} 항목을 선택해 주세요.`
      },
    },
  })

  return (
    <>
      <div className={'text-[white] text-[18px] font-semibold leading-[1.5] mb-[12px]'}>
        {title}
      </div>
      <div className={'w-full flex'}>
        {list.map((item) => (
          <QRadio
            type={'button'}
            key={`${item.attributeId}_${item.attributeValueId}`}
            name={`${item.attributeId}_${item.attributeValueId}`}
            value={item.attributeValueId}
            defaultValue={field.value.selectedAttributeValueId}
            onChange={(nextVal) => {
              const changeValue = field.value
              changeValue.selectedAttributeValueId = +nextVal
              field.onChange(changeValue)
            }}
          >
            <QRadio.ButtonLabel>{item.value}</QRadio.ButtonLabel>
          </QRadio>
        ))}
      </div>
      {fieldState?.error && (
        <p className={'text-[#FC5454] text-[12px] font-medium mt-[8px]'}>
          {fieldState.error.message}
        </p>
      )}
    </>
  )
}
