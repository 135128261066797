import React, { useState } from 'react'

export type TAppDownload = {
  id: string
  imgSrcPc: any
  imgSrcMo: any
  onClick?: () => void
}

const AppDownloadItems: TAppDownload[] = [
  {
    id: 'googleplay',
    imgSrcPc: require('../../../assets/img/landing/mainBanner/google_play.png'),
    imgSrcMo: require('../../../assets/img/landing/mainBanner/m_google_play.png'),
    onClick: () => window.open(process.env.REACT_APP_GOOGLE_PLAY_STORE_URL, '_target'),
  },
  {
    id: 'appstore',
    imgSrcPc: require('../../../assets/img/landing/mainBanner/app_store.png'),
    imgSrcMo: require('../../../assets/img/landing/mainBanner/m_app_store.png'),
    onClick: () => window.open(process.env.REACT_APP_APP_STORE_URL, '_target'),
  },
]

export default function LandingMainBannerSection({
  isDesktop,
}: {
  isDesktop: boolean
}): React.JSX.Element {
  const [isDownload, setIsDownload] = useState(false)

  const handleClickDownload = () => {
    setIsDownload(true)
  }
  return (
    <section className={`h-[100vh] overflow-hidden main-bg ${isDesktop ? 'pc' : 'mo'}`}>
      <article className={'landing-container flex justify-center items-end tablet:items-center'}>
        <div
          className={
            'w-full h-[80%] tablet:h-full flex justify-between items-center flex-col tablet:flex-row'
          }
        >
          <div className={'text-white text-center tablet:text-left tablet:h-[450px]'}>
            <h1
              className={
                'text-[28px] tablet:text-[64px] font-bold leading-[1.5] tablet:leading-[1.4] tracking-[-0.28px] tablet:tracking-[-0.64px] transition-all'
              }
            >
              안쓰는 전자제품
              <br />
              찍어 올리면, 바로 입금
            </h1>
            <p
              className={
                'mt-[8px] tablet:mt-[24px] text-[16px] tablet:text-[32px] leading-[1.6] tablet:leading-[1.4] tracking-[-0.04px] tablet:tracking-[-0.08px] transition-all'
              }
            >
              전자제품 중고거래 필수 앱, 퀵셀
            </p>

            <div className={'mt-[32px] tablet:mt-[80px] relative tablet:static'}>
              {isDownload ? (
                <>
                  <div
                    className={
                      ' tablet:py-[48px] tablet:px-[56px] p-[32px] rounded-[24px] bg-[#394150] flex flex-col tablet:flex-row justify-between items-center tablet:static tablet:left-0 tablet:translate-x-[0] absolute top-[0] left-[50%] translate-x-[-50%] '
                    }
                  >
                    <div
                      className={
                        ' w-[96px] h-[96px] tablet:w-[140px] tablet:h-[140px] bg-white text-black'
                      }
                    >
                      <img
                        className={'w-fit'}
                        src={require(`../../../assets/img/landing/mainBanner/qrcode.png`)}
                        alt="qrcode"
                      />
                    </div>
                    <div
                      className={'w-[255px] hidden tablet:flex flex-col gap-[20px] items-center'}
                    >
                      {AppDownloadItems.map((item) => (
                        <button
                          key={item.id}
                          className={
                            'border-b-[1px] border-[#4D5562] last-of-type:pb-0 last-of-type:border-none pb-[20px]'
                          }
                          onClick={item.onClick}
                        >
                          <img
                            className={'h-[40px] cursor-pointer none-drag px-[20px]'}
                            src={item.imgSrcPc}
                            alt={item.id}
                          />
                        </button>
                      ))}
                    </div>
                    <div
                      className={
                        'w-[232px] flex tablet:hidden flex-col gap-[20px] items-center mt-[32px]'
                      }
                    >
                      {AppDownloadItems.map((item) => (
                        <button
                          key={item.id}
                          className={
                            'border-b-[1px] border-[#4D5562] last-of-type:pb-0 last-of-type:border-none pb-[20px]'
                          }
                          onClick={item.onClick}
                        >
                          <img
                            key={item.id}
                            className={'h-[28px] none-drag cursor-pointer  px-[40px]'}
                            src={item.imgSrcMo}
                            alt={item.id}
                          />
                        </button>
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                <button
                  className={
                    'inline-flex bg-[#29A5FF] py-[18px] px-[32px] text-[18px] tablet:py-[36px] tablet:px-[86px] rounded-[112px] tablet:text-[28px] leading-[140%] font-bold cursor-pointer'
                  }
                  onClick={handleClickDownload}
                >
                  앱 다운로드
                </button>
              )}
            </div>
          </div>
          <img
            className={'mt-[40px] tablet:mt-0 hidden tablet:block none-drag'}
            src={require(`../../../assets/img/landing/mainBanner/phone_frame_pc.png`)}
            alt=""
          />
          <img
            className={'mt-[40px] tablet:mt-0 tablet:hidden min-w-[200px] w-[40%] none-drag'}
            src={require(`../../../assets/img/landing/mainBanner/phone_frame_mo.png`)}
            alt=""
          />
        </div>
      </article>
    </section>
  )
}
