import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { TPopupActions, TPopupState } from './types'

const popupStore = (set): TPopupState & TPopupActions => ({
  isAlert: false,
  defaultAlert: {
    title: '',
    contents: '',
    isOutsideClose: true,
    maxWidth: '380px',
    closeText: '확인',
    handleClose: null,
  },
  defaultConfirm: {
    title: '',
    contents: '',
    maxWidth: '380px',
    closeText: '취소',
    handleClose: null,
    submitText: '저장',
    submitType: 'primary',
    handleSubmit: null,
  },
  alert: {
    title: '',
    contents: '',
    isOutsideClose: true,
    maxWidth: '380px',
    closeText: '확인',
    handleClose: null,
  },
  isConfirm: false,
  confirm: {
    title: '',
    contents: '',
    maxWidth: '380px',
    closeText: '취소',
    handleClose: null,
    submitText: '저장',
    submitType: 'primary',
    handleSubmit: null,
  },
  isLoading: false,
  loading: {
    text: '',
  },
  defaultLoading: {
    text: '',
  },
  setAlert: (alertInfo) => {
    set((state) => {
      state.alert = { ...state.defaultAlert, ...alertInfo }
    })
  },
  setAlertVisible: (isVisible) => {
    set((state) => {
      state.isAlert = isVisible
    })
  },
  setConfirm: (confirmInfo) => {
    set((state) => {
      state.confirm = { ...state.defaultConfirm, ...confirmInfo }
    })
  },
  setConfirmVisible: (isVisible) => {
    set((state) => {
      state.isConfirm = isVisible
    })
  },
  setLoading: (loadingInfo) => {
    set((state) => {
      state.loading = { ...state.defaultLoading, ...loadingInfo }
    })
  },
  setLoadingVisible: (isVisible) => {
    set((state) => {
      state.isLoading = isVisible
    })
  },
})
export const usePopupStore = create<TPopupState & TPopupActions>()(
  devtools(immer(popupStore), { enabled: process.env.NODE_ENV !== 'production' }),
)
