'use client'
import React, {
  ChangeEvent,
  forwardRef,
  memo,
  PropsWithChildren,
  Ref,
  useCallback,
  useEffect,
  useState,
} from 'react'
import InputPwIcon from './components/pwIcon'
import { replaceNumberWordFilter } from '../../utils/format'

export type TInputValue = number | string | undefined
type TInputType = 'text' | 'password' | 'tel' | 'number'
type TInputProps = {
  line?: 'none' | 'default'
  theme?: 'light' | 'dark'
  value?: TInputValue
  type?: TInputType
  size?: 'small' | 'middle' | 'large'
  name?: string
  disabled?: boolean
  readonly?: boolean
  color?: 'green' | 'red'
  placeholder?: string
  maxLength?: number
  viewLengthText?: boolean
  min?: number
  max?: number
  isNumTextView?: boolean
  isPasteCheck?: boolean
  onChange?: (val?: TInputValue, e?: ChangeEvent<HTMLInputElement>) => void
  onBlur?: (val?: TInputValue, e?: ChangeEvent<HTMLInputElement>) => void
  onFocus?: (val?: TInputValue, e?: ChangeEvent<HTMLInputElement>) => void
  onEnter?: (val?: TInputValue, e?: React.KeyboardEvent<HTMLInputElement>) => void
  onClick?: (val?: TInputValue, e?: React.MouseEvent<HTMLInputElement>) => void
}

const lineClass = {
  none: 'outline-none group-[.is-error]/form:border-none',
  default: 'border-[1px] border-gray-200',
}

const sizeClass = {
  small: '',
  middle: 'h-[56px] p-[12px] text-[16px]',
  large: '',
}

const colorClass = {
  green: 'input-success',
  red: 'input-error',
}

const themeClass = {
  light: '',
  dark: 'text-white placeholder:text-gray-500 placeholder:font-medium bg-[#394150] border-none rounded-[8px] ',
}

function InputIndex(
  { ...props }: PropsWithChildren<TInputProps>,
  ref: Ref<HTMLInputElement>,
): React.JSX.Element {
  const {
    line = 'default',
    value,
    type = 'text',
    theme = 'light',
    size = 'middle',
    name,
    disabled,
    readonly = false,
    color,
    placeholder = '입력해주세요.',
    maxLength,
    viewLengthText = false,
    min = -999999999999999,
    max = 999999999999999,
    isNumTextView = false,
    isPasteCheck = false,
    onChange,
    onBlur,
    onFocus,
    onEnter,
    onClick,
  } = props

  const [inputValue, setInputValue] = useState<TInputValue>('')
  const [textLength, setTextLength] = useState<number>(0)
  const [inputType, setInputType] = useState<TInputType>(type || 'text')
  const [isViewPassword, setIsViewPassword] = useState<boolean>(false)
  const [isPaste, setIsPaste] = useState<boolean>(false)

  const inputClassNames = [
    'input w-full focus:outline-0 group-[.is-error]/form:border-red-500',
    sizeClass[size],
    line && lineClass[line],
    color && colorClass[color],
    type === 'password' && 'pr-[34px]',
    theme && themeClass[theme],
  ].filter((className) => !!className)

  const handleKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (onEnter && typeof onEnter === 'function') {
        const result = valueCheck(e.currentTarget.value)
        onEnter(result, e)
      }
    }
  }

  useEffect(
    () => {
      valueCheck(value)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value],
  )

  const valueCheck = useCallback(
    (val: TInputValue) => {
      if (val === undefined || val === null) {
        setInputValue('')
        return ''
      }

      let resultValue = ''
      if (type === 'number') {
        const numberValue = parseInt(val as string)
        if (isNaN(numberValue) || !isFinite(numberValue)) {
          setInputValue('')
          return ''
        }

        if (numberValue < min) {
          resultValue = min.toString()
        } else if (numberValue > max) {
          resultValue = max.toString()
        } else {
          resultValue = numberValue.toString()
        }
      } else if (type === 'tel') {
        resultValue = val.toString().replace(/[^0-9]/g, '')
      } else {
        resultValue = val.toString()
      }
      setInputValue(resultValue)
      return resultValue
    },
    [max, min, type],
  )

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { type, target } = e
      let resultValue = valueCheck(target.value)

      if (isPasteCheck && isPaste) {
        const regURL = /(http|https|ftp|telnet|news|irc):\/\/([-/.a-zA-Z0-9_~#%$?&=:200-377()]+)/gi
        const regText = resultValue.match(regURL)
        if (regText) {
          resultValue = regText[0]
        }
        if (onChange && typeof onChange === 'function') {
          onChange(resultValue, e as ChangeEvent<HTMLInputElement>)
        }
        setIsPaste(false)
      } else {
        if (type === 'change') {
          if (onChange && typeof onChange === 'function') {
            onChange(resultValue, e as ChangeEvent<HTMLInputElement>)
          }
          if (maxLength && maxLength > 0) {
            setTextLength(e.target.value.length)
          }
        } else if (type === 'focus') {
          if (onFocus && typeof onFocus === 'function') {
            onFocus(resultValue, e)
          }
        } else if (type === 'blur') {
          if (onBlur && typeof onBlur === 'function') {
            onBlur(resultValue, e)
          }
        }
      }
    },
    [valueCheck, isPasteCheck, isPaste, onChange, maxLength, onFocus, onBlur],
  )

  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    const { currentTarget } = e
    const resultValue = valueCheck(currentTarget.value)
    if (onClick && typeof onClick === 'function') {
      onClick(resultValue, e)
    }
  }

  const handleClickViewPassword = () => {
    setInputType(() => (!isViewPassword ? 'text' : 'password'))
    setIsViewPassword((prev) => !prev)
  }

  const handlePaste = useCallback(() => {
    setIsPaste(true)
  }, [])

  return (
    <div className={`relative w-full`}>
      {viewLengthText && maxLength && (
        <span className={`absolute bottom-0 right-[4px] text-gray-400 text-[12px]`}>
          {textLength}
          <span className={`mx-[2px]`}>/</span>
          {maxLength}
        </span>
      )}
      <input
        ref={ref}
        type={inputType}
        name={name}
        value={inputValue}
        placeholder={placeholder}
        className={`${inputClassNames.join(' ')} `}
        maxLength={maxLength}
        disabled={disabled}
        readOnly={readonly}
        onChange={handleChange}
        onKeyDown={handleKeydown}
        onClick={handleClick}
        onPaste={handlePaste}
      />
      {isNumTextView && type === 'number' && (
        <p className={'text-[14px] text-green-600 text-right'}>
          {replaceNumberWordFilter(inputValue)}
        </p>
      )}
      {type === 'password' && (
        <button
          className={`absolute bottom-[50%] right-[8px] text-gray-400 text-[12px] translate-y-[50%]`}
          onClick={handleClickViewPassword}
        >
          <InputPwIcon isView={isViewPassword} />
        </button>
      )}
    </div>
  )
}

export const QInput = memo(forwardRef<HTMLInputElement, TInputProps>(InputIndex))
