import Dialog from '@mui/material/Dialog'
import { usePopupStore } from '../../store/popup'
import { useCallback } from 'react'
import { QButton } from '../Button'

export default function AlertDialog() {
  const popupStore = usePopupStore()

  const close = useCallback(() => {
    popupStore.setAlertVisible(false)
  }, [popupStore])

  const handleClose = useCallback(
    async (e?: any, reason?: any) => {
      if (!popupStore.alert.isOutsideClose && reason === 'backdropClick') {
        return null
      }

      if (popupStore.alert.handleClose && typeof popupStore.alert.handleClose === 'function') {
        await popupStore.alert.handleClose(close)
      } else {
        close()
      }
    },
    [close, popupStore.alert],
  )

  return (
    <Dialog
      open={popupStore.isAlert}
      scroll={'paper'}
      fullWidth={true}
      onClose={handleClose}
      classes={{
        root: '',
        paper: `!rounded-[24px] !m-0 !w-auto !max-w-[280px]`,
        container: 'min-w-[280px]',
      }}
    >
      <div className={`pt-[32px] pb-[16px] px-[16px] flex flex-col text-center`}>
        <h2 className={'text-[18px] font-semibold leading-[1.5] text-[#394150] m-0'}>
          {popupStore.alert.title}
        </h2>
        <div className={'mt-[8px] text-[14px] leading-[1.6] text-[#A0A6B1] break-words'}>
          {typeof popupStore.alert.contents === 'string'
            ? popupStore.alert.contents
            : popupStore.alert.contents()}
        </div>
        <div className={'mt-[24px]'}>
          <QButton size={'small'} color={'dark-gray'} onClick={() => handleClose()}>
            <span className={'font-semibold'}>{popupStore.alert.closeText}</span>
          </QButton>
        </div>
      </div>
    </Dialog>
  )
}
