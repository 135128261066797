import React, { useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { postLoginByCode } from '../../../apis/api/common'
import { setToken } from '../../../utils/token'
import { usePopupStore } from '../../../store/popup'
import { toast } from '../../../components/Toast'

export default function PageRedirectLogin(): React.JSX.Element {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const params = useParams()
  const popupStore = usePopupStore()

  useEffect(() => {
    if (params.loginType === 'kakao') {
      const code = searchParams.get('code')
      popupStore.setLoadingVisible(true)
      postLoginByCode({
        code: code,
        loginTypeCd: 'KAKAO',
        redirectUrl: window.location.origin + window.location.pathname,
      })
        .then(({ context }) => {
          setToken({
            accessToken: context.tokenInfo.access_token,
            refreshToken: context.tokenInfo.refresh_token,
          })
          popupStore.setLoadingVisible(false)
          navigate('/promotion/sell/url-import', { replace: true })
        })
        .catch((err) => {
          console.error('postLoginByCode err :: ', err)
          popupStore.setLoadingVisible(false)
          navigate('/promotion/sell', { replace: true })
        })
    } else {
      toast('잘못된 접근입니다..').red()
      navigate('/promotion/sell', { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <main className={'bg-black'}>
      <div
        className={
          'w-full min-h-screen max-w-[768px] min-w-[280px] m-auto flex flex-col bg-[#171c27] justify-center items-center'
        }
      >
        <h1 className={'text-[16px] text-white mb-16'}>로그인 진행 중입니다.</h1>
      </div>
    </main>
  )
}
