import { isIOS } from 'react-device-detect'

export const copyToClipboard = (copyContent: string): void => {
  if (document) {
    const tempTextArea = document.createElement('textarea')
    tempTextArea.setAttribute('readonly', 'readonly')
    document.body.appendChild(tempTextArea)
    tempTextArea.value = copyContent
    tempTextArea.select()
    document.execCommand('copy')
    document.body.removeChild(tempTextArea)
  }
}

export const onClickFacebookShare = () => {
  window && window.open(`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`)
}

export const onClickMsgShare = () => {
  location.href = `sms:${isIOS ? '&' : '?'}body=${window.location.href}`
}

export const onClickCopyURL = () => {
  copyToClipboard(`${window.location.href}`)
}
