import { toast as toastify, ToastContainer, ToastOptions, ToastPosition } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

type TToastParams = {
  position?: ToastPosition
  autoClose?: number
  theme?: string
  progress?: boolean
  drag?: boolean
}

export const toast = (
  text = '',
  {
    position = 'top-center',
    autoClose = 2000,
    theme,
    progress = true,
    drag = true,
  }: TToastParams = {},
) => {
  const options = {
    // className:
    //   '!w-[80%] !mt-[8px] !mx-auto !bg-green-100 !border !border-green-200 !text-[14px] !text-green-500 !rounded-md !shadow-md ![&>.Toastify__toast-body]:my-0',
    className:
      '!w-[80%] !mt-[8px] !mx-auto !border !text-[14px] !rounded-md !shadow-md ![&>.Toastify__toast-body]:my-0',
    position: position,
    autoClose: autoClose,
    hideProgressBar: progress,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: drag,
    progress: undefined,
    closeButton: false,
    theme: theme,
  } as ToastOptions

  return {
    blue: () => {
      options.className += ' !bg-blue-100 !border-blue-200 !text-blue-500'
      toastify.info(text, options)
    },
    red: () => {
      options.className += ' !bg-red-100 !border-red-200 !text-red-500'
      toastify.error(text, options)
    },
    green: () => {
      options.className += ' !bg-green-100 !border-green-200 !text-green-500'
      toastify.success(text, options)
    },
    yellow: () => {
      options.className += ' !bg-yellow-100 !border-yellow-200 !text-yellow-500'
      toastify.warn(text, options)
    },
  }
}

export const toastClearWaitingQueue = toastify.clearWaitingQueue

export const Toast = () => {
  return <ToastContainer />
}
