import React, { useEffect, useState } from 'react'
import { QButton } from '../../../../components/Button'
import PromotionSellSlideBanner from './components/SlideBanner'
import PromotionSellTitle from '../components/Title'
import PromotionSellSubTitle from '../components/SubTitle'
import PromotionSellHeader from '../components/Header'
import { getBundleServiceDeals } from '../../../../apis/api/promotion'
import { TResBundleServiceDeals } from '../../../../apis/api/promotion/types'
import { useLoadableVariation } from '@hackler/react-sdk'
import { kakaoSdkLoad, kakaoSdkLogin } from '../../../../utils/kakaoSdk'

export default function PagePromotionSellIntro(): React.JSX.Element {
  const [list, setList] = useState<TResBundleServiceDeals[]>([])

  const { variation } = useLoadableVariation(19)
  const handleClickLogin = () => {
    kakaoSdkLogin({
      redirectUri: `${window.location.origin}/redirect/kakao`,
    })
  }

  useEffect(() => {
    getBundleServiceDeals({ 'bundle-status-cd': 'COMPLETE', page: 1, size: 15 })
      .then(({ context }) => {
        setList(context.bundles)
      })
      .catch(() => {
        setList([])
      })
    kakaoSdkLoad(process.env.REACT_APP_KAKAO_KEY)
  }, [])

  return (
    <div className={'flex flex-col justify-between min-h-screen promotion-bg'}>
      <PromotionSellHeader isVisible={false} />
      <div className={'px-[32px] py-[48px] inline-flex flex-col justify-between flex-1'}>
        <PromotionSellTitle>
          즉시 입금되는
          <br />
          퀵셀에 팔아보세요
        </PromotionSellTitle>
        <div>
          <PromotionSellSubTitle>가장 최근, 퀵셀에서 팔린 제품들이에요</PromotionSellSubTitle>
          <div className={'w-full bg-[#272f3e] mt-[24px] rounded-[8px] overflow-hidden relative'}>
            <PromotionSellSlideBanner list={list} variation={variation} />
          </div>
          <QButton
            color={'yellow'}
            addClass={'mt-[32px] inline-flex items-center justify-center'}
            onClick={handleClickLogin}
          >
            <img
              className={'w-[32px] h-[32px] mr-[4px]'}
              src={require(`../../../../assets/icon/kakao_talk.png`)}
              alt="kakao login"
            />
            <span className={'text-[#391B1B] text-[18px] font-semibold'}>카카오 로그인</span>
          </QButton>
        </div>
      </div>
    </div>
  )
}
