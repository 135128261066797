import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'

export default function PageTerms(): React.JSX.Element {
  const params = useParams()
  const navigate = useNavigate()
  const [title, setTitle] = useState<string>('')
  const [contents, setContents] = useState<string>('')

  const getDetail = useCallback(async () => {
    try {
      const test = await axios.get(
        `${process.env.REACT_APP_API_USER_DOMAIN}/v1/allows/term/${params.type.toUpperCase()}`,
      )
      const { title, contents } = test.data.context
      setTitle(title)
      setContents(contents)
    } catch (e) {
      console.error(e)
      setTitle('')
      setContents('')
      navigate('/', { replace: true })
    }
  }, [navigate, params.type])

  useEffect(() => {
    if (params.type) {
      getDetail()
    } else {
      navigate('/', { replace: true })
    }
  }, [getDetail, navigate, params.type])

  return (
    <section className={'editor p-[20px]'}>
      <div className={'w-full min-w-[360px] max-w-[960px] mx-auto'}>
        <h1 className={'text-[24px]'}>{title}</h1>

        <div className={'mt-12'} dangerouslySetInnerHTML={{ __html: contents }}></div>
      </div>
    </section>
  )
}
