import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function PageNotFound(): React.JSX.Element {
  const navigate = useNavigate()
  return (
    <div className="w-full h-[100vh] flex justify-center items-center">
      <div className="border-[1px] border-white px-5 py-3">
        <h2 className="text-[100px] text-gray-300">404</h2>
        <p className="text-[40px] font-medium text-gray-300">Page Not Found</p>
        <p className="text-[20px] font-medium text-gray-300">
          요청하신 페이지가 사라졌거나, 잘못된 경로를 이용하였습니다.
        </p>
        <button
          type={'button'}
          className={
            'border border-indigo-500 bg-indigo-500 text-white rounded-md px-4 py-2 mt-[24px] transition duration-500 ease select-none hover:bg-indigo-600 focus:outline-none focus:shadow-outline'
          }
          onClick={() => navigate('/')}
        >
          메인으로 가기
        </button>
      </div>
    </div>
  )
}
