import Dialog from '@mui/material/Dialog'
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Slider from 'react-slick'

type TPreviewDialogProps = {
  list: string[]
  targetPage?: number
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  setImageIndex: Dispatch<SetStateAction<number>>
}
export default function PreviewDialog({
  list = [],
  targetPage,
  open,
  setOpen,
  setImageIndex,
}: TPreviewDialogProps) {
  const sliderRef = useRef<Slider>(null)

  const [currentPage, setCurrentPage] = useState<number>(0)

  const handleClose = useCallback(() => {
    setImageIndex(null)
    setOpen(false)
  }, [setImageIndex, setOpen])

  const settings = {
    dots: false,
    className: 'w-[calc(80vw)] max-w-[80%] h-auto',
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    adaptiveHeight: true,
    arrows: false,
    initialSlide: targetPage,
    beforeChange: (current, next) => {
      setCurrentPage(next)
    },
  }

  useEffect(() => {
    setCurrentPage(targetPage)
  }, [targetPage])

  useEffect(
    () => {
      if (sliderRef?.current) {
        sliderRef?.current.slickGoTo(targetPage)
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [sliderRef?.current],
  )

  return (
    <Dialog
      fullScreen
      classes={{
        paper: '!bg-[rgba(0,0,0,0.75)] justify-center items-center',
      }}
      open={open}
      onClose={handleClose}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[100],
        }}
      >
        <CloseIcon />
      </IconButton>
      {open && (
        <Slider ref={sliderRef} {...settings}>
          {list.map((item) => (
            <div className={'!flex !justify-center !items-center text-center'} key={item}>
              <div className={`p-[4px] max-w-[60%]`}>
                <img className={'w-full h-auto'} src={item} alt={'1'} />
              </div>
            </div>
          ))}
        </Slider>
      )}
      <div
        className={
          'absolute left-1/2 translate-x-[-50%] bottom-[80px] text-gray-300 border-2 border-gray-200'
        }
      >
        <span>{`${currentPage + 1}/${list.length}`}</span>
      </div>
    </Dialog>
  )
}
