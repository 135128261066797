import React from 'react'
import { Outlet } from 'react-router-dom'
import './preflight.css'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'

export default function DefaultLayout(): React.JSX.Element {
  return (
    <>
      <div className={'w-0 h-0 overflow-hidden'}>
        <p>퀵셀 - 빠른 중고 전자제품 판매</p>
        <p>
          중고거래, 중고, 중고전자제품, 중고폰, 중고스마트폰, 중고태블릿, 중고닌텐도, 중고게임기,
          중고가전제품, 중고가전판매, 전자기기, 에어팟프로, 에어팟, 갤럭시버즈, 닌텐도 스위치,
          갤럭시워치, 애플워치, 갤럭시탭, 아이패드, 전자제품, 리퍼비시, 리퍼브, 가전제품, 전자랜드,
          전자제품비교, 가전제품비교, 중고가전제품, 중고가전판매, 전자기기, 리퍼전자제품, IT기기,
          중고거래, 중고직거래, 직거래
        </p>
      </div>

      <div className={'preflight'}>
        <Outlet />
        <ToastContainer />
      </div>
    </>
  )
}
