import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { createInstance, HackleProvider } from '@hackler/react-sdk'
import { getAccessToken } from '../../../utils/token'

export default function LayoutPromotionSell(): React.JSX.Element {
  const navigate = useNavigate()
  const location = useLocation()
  const hackleClient = createInstance(process.env.REACT_APP_HACKLE_SDK_KEY, {
    debug: process.env.NODE_ENV !== 'production',
  })
  // 새로고침 시 첫화면으로 이동
  useEffect(
    () => {
      if (!location.pathname.includes('/promotion/sell')) {
        if (location.pathname.includes('/promotion/sell/url-import')) {
          if (!getAccessToken()) {
            navigate('/promotion/sell', { replace: true })
          }
        } else {
          navigate('/promotion/sell', { replace: true })
        }
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
  return (
    <HackleProvider hackleClient={hackleClient}>
      <main className={'bg-black'}>
        <div
          className={
            'w-full min-h-screen max-w-[768px] min-w-[280px] m-auto flex flex-col bg-[#171c27]'
          }
        >
          <Outlet />
        </div>
      </main>
    </HackleProvider>
  )
}
