import Cookies from 'js-cookie'

export const getAccessToken = () => {
  return (Cookies.get('beah') && Cookies.get('beah') !== '') ||
    (Cookies.get('beap') && Cookies.get('beap') !== '') ||
    (Cookies.get('beas') && Cookies.get('beas') !== '')
    ? Cookies.get('beah') + '.' + Cookies.get('beap') + '.' + Cookies.get('beas')
    : null
}

export const getRefreshToken = () => {
  return Cookies.get('bert') && Cookies.get('bert') !== '' ? Cookies.get('bert') : null
}

type TToken = {
  accessToken: string
  refreshToken: string
}
export const setToken = (data: TToken) => {
  const tokenArr = data.accessToken.split('.')
  Cookies.set('beah', tokenArr[0])
  Cookies.set('beap', tokenArr[1])
  Cookies.set('beas', tokenArr[2])
  Cookies.set('bert', data.refreshToken)
}

export const removeToken = () => {
  Cookies.remove('beah')
  Cookies.remove('beap')
  Cookies.remove('beas')
  Cookies.remove('bert')
}

export const getAccessTokenServer = (cookies: any) => {
  const h = cookies.get('beah')
  const p = cookies.get('beap')
  const s = cookies.get('beas')

  return h && h?.value !== '' && p && p?.value !== '' && s && s?.value !== ''
    ? [h?.value, p?.value, s?.value].join('.')
    : null
}

export const removeTokenServer = (cookies: any) => {
  cookies.delete('beah')
  cookies.delete('beap')
  cookies.delete('beas')
  cookies.delete('bert')
}
