import axios from 'axios'
import { toast } from '../../components/Toast'
import { getAccessToken } from '../../utils/token'

const commonRequest = axios.create({
  baseURL: process.env.REACT_APP_API_COMMON_DOMAIN,
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000,
})

commonRequest.interceptors.request.use(
  (config) => {
    if (getAccessToken() && !config.url.includes('allows')) {
      config.headers.Authorization = `bearer ${getAccessToken()}`
    }
    config.headers['Cache-Control'] = 'no-cache'
    config.headers.Pragma = 'no-cache'
    config.headers.Expires = '0'
    config.paramsSerializer = function (paramObj) {
      const params = new URLSearchParams()
      for (const key in paramObj) {
        if (typeof paramObj[key] === 'object') {
          if (paramObj[key] !== null && paramObj[key].length > 0) {
            paramObj[key].forEach((obj) => {
              params.append(key, obj)
            })
          }
        } else {
          params.append(key, paramObj[key])
        }
      }
      return params.toString()
    }
    return config
  },
  (error) => {
    console.error(error)
    return Promise.reject(error)
  },
)

commonRequest.interceptors.response.use(
  (response) => {
    const res = response.data
    if (res.return_code && res.return_code !== 0) {
      return Promise.reject(res)
    } else {
      return res
    }
  },
  (error) => {
    if (error && error.response) {
      console.error(error.response.data)
      const data = error.response.data
      if (error?.response.data?.return_code < 30000) {
        // toast(`[${data.returnCode}] ${JSON.stringify(data?.context)}`).red()
        return Promise.reject(error.response.data)
      } else if (error?.response.data?.return_code >= 30000) {
        toast(`[${data.returnCode}] ${data.returnMessage} / ${JSON.stringify(data?.context)}`).red()
        return Promise.reject(error.response.data)
      } else {
        if (data?.returnCode) {
          toast(`[${data.returnCode}] ${JSON.stringify(data?.context)}`).red()
        } else {
          toast(`[${error.response.status}] ${data.error}`).red()
        }
        return Promise.reject(error.response.data)
      }
    } else {
      return Promise.reject(error)
    }
  },
)
export default commonRequest
