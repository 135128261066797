import React, { useState } from 'react'
import { QButton } from '../../components/Button'
import QToggleButton from '../../components/QToggleButton'
import { QInput } from '../../components/Input'
import { QRadio } from '../../components/Radio'

export default function PageSample(): React.JSX.Element {
  // ---여기부터 테스트 작업---

  const [image, setImage] = useState('')
  const handleDoubleClick = () => {
    const randomImage =
      Math.random() < 0.5
        ? 'https://i.namu.wiki/i/d1JZchjts9iX9iqUO2vsep83Z659z-WLjgqUuNF-xBXknI8vmJdZK4UazqfcTxfj3yIo-8Gjefpthq9V2pCgew.gif'
        : 'https://i.namu.wiki/i/F50JHzlMK_aXuGb8IoFIn4tLbY-yxny3brqSTWsNDouq27aSGxpIbZgfxZK-JVzufHWnvw74yh6CFN4fb716TQ.gif'

    setImage(randomImage)
  }

  const [radioT, setRadioT] = useState('개봉')

  // ---여기까지 테스트 작업---

  const [radio, setRadio] = useState('도걸')

  return (
    <div className={'w-screen h-screen overflow-scroll bg-gray-900 p-20'}>
      <div className={'bg-white p-10'}>
        <h1>버튼타입 라디오 테스트</h1>
        <QToggleButton count={'2'} contents={['개봉', '미개봉']} />
        <QToggleButton count={'3'} contents={['개봉', '미개봉', '개봉새상품']} />
        <QInput theme={'light'} />
        <QButton
          onClick={() => {
            window.location.href = 'quicksellrun://page?path=/'
          }}
        >
          test123123123
        </QButton>
      </div>

      <div className={'bg-white p-10 text-white w-[600px]'}>
        <h1>선택 값 :: {radioT}</h1>
        <div className={'w-full flex'}>
          <QRadio
            name={'buttonRadio'}
            value={'개봉'}
            type={'button'}
            defaultValue={radioT}
            onChange={(val) => setRadioT(val)}
          >
            <QRadio.ButtonLabel>개봉</QRadio.ButtonLabel>
          </QRadio>
          <QRadio
            name={'buttonRadio'}
            value={'aa'}
            type={'button'}
            defaultValue={radioT}
            onChange={(val) => setRadioT(val)}
          >
            <QRadio.ButtonLabel>aa</QRadio.ButtonLabel>
          </QRadio>
          <QRadio
            name={'buttonRadio'}
            value={'미개봉'}
            type={'button'}
            defaultValue={radioT}
            onChange={(val) => setRadioT(val)}
          >
            <QRadio.ButtonLabel>미개봉</QRadio.ButtonLabel>
          </QRadio>
        </div>
      </div>

      <h1 className={'text-white'}>
        라디오가 사라져서 놀라지 마세요 도걸림 코드는 이 아래에 주석되어 있답니다.
      </h1>

      <div>
        <button onDoubleClick={handleDoubleClick}>보통통이를 찾아보세요</button>
        {image && <img src={image} alt="랜덤 이미지" />}
      </div>

      {/*기존코드*/}
      <div className={'bg-[#181E2D] p-10 text-white'}>
        <h1>라디오 :: {radio}</h1>
        <QRadio
          name={'default'}
          value={'1234'}
          defaultValue={radio}
          onChange={(val) => setRadio(val)}
        >
          <QRadio.RadioLabel>화이트 티타늄</QRadio.RadioLabel>
        </QRadio>
        <QRadio
          name={'default'}
          value={'aaa'}
          defaultValue={radio}
          onChange={(val) => setRadio(val)}
        >
          <QRadio.RadioLabel>화이트 티타늄2</QRadio.RadioLabel>
        </QRadio>
      </div>
    </div>
  )
}
