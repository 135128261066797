import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { TResBundleServiceDeals } from '../../../../../../apis/api/promotion/types'
import dayjs from 'dayjs'

type TPromotionAnimation = {
  list: TResBundleServiceDeals[]
  variation?: string
}

export default function PromotionSellSlideBanner({
  list,
  variation,
}: TPromotionAnimation): React.JSX.Element {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: false,
    autoplay: true,
    pauseOnHover: false,
    swipeToSlide: false,
    speed: 1000,
    autoplaySpeed: 1000,
    arrows: false,
  }

  return (
    <div className={'px-[24px] pt-[16px] overflow-hidden'}>
      <Slider {...settings}>
        {list.map((item) => (
          <div key={item.id} className={'w-full py-[10px]'}>
            <ul className={'w-full text-white flex justify-between gap-[20px]'}>
              <li className={'text-[14px] font-medium fold:text-[12px] transition-all truncate'}>
                {item.productName}
              </li>
              <li
                className={
                  'max-w-[120px] w-full text-[14px] font-medium text-right flex flex-col fold:text-[12px] transition-all flex-shrink-0'
                }
              >
                {variation && variation === 'B' && (
                  <span className={'mb-[4px]'}>{item.price.toLocaleString()}원</span>
                )}
                <span
                  className={
                    'text-[12px] font-normal text-[#6C727F] fold:text-[10px] transition-all'
                  }
                >
                  {dayjs(item.createdAt).format('YYYY-MM-DD HH:mm')}
                </span>
              </li>
            </ul>
          </div>
        ))}
      </Slider>
    </div>
  )
}
