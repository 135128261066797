/*
 * 2023-12-10 : Den
 * https://developers.kakao.com/docs/latest/ko/kakaologin/js#request-token
 * */
export type TKakaoSdkLoginParams = {
  redirectUri: string
  scope?: string
  throughTalk?: boolean
  prompt?: string
  loginHint?: string
  serviceTerms?: string
  state?: string
  nonce?: string
}

export const kakaoSdkLoad = async (appKey: string) => {
  await createScript(appKey)
}
export const kakaoSdkLogin = async (params: TKakaoSdkLoginParams) => {
  window.Kakao.Auth.authorize(params)
}

// https://developers.kakao.com/docs/latest/ko/javascript/download#changelog
const createScript = (appKey: string) => {
  return new Promise((resolve) => {
    const id = 'kakao_sdk_script_id'
    const el = document.getElementById(id)
    if (!el) {
      const scriptElement = document.createElement('script')
      scriptElement.setAttribute('src', 'https://t1.kakaocdn.net/kakao_js_sdk/2.5.0/kakao.min.js')
      scriptElement.setAttribute(
        'integrity',
        'sha384-kYPsUbBPlktXsY6/oNHSUDZoTX6+YI51f63jCPEIPFP09ttByAdxd2mEjKuhdqn4',
      )
      scriptElement.setAttribute('crossorigin', 'anonymous')
      scriptElement.setAttribute('id', id)
      scriptElement.onload = () => loadingSdk(appKey)
      document.head.appendChild(scriptElement)
    }
    resolve(true)
  })
}

// script 호출이후 바로 로딩이 불가..계속 체킹..필요할듯
const loadingSdk = (appKey: string) => {
  if (window.Kakao && !window.Kakao.isInitialized()) {
    window.Kakao.init(appKey)
  }
}

declare global {
  interface Window {
    Kakao: any
  }
}
