import React from 'react'
import { Route, Routes } from 'react-router-dom'
import PageHome from './pages/Home/index'
import PageTerms from './pages/Terms'
import PageNotFound from './pages/NotFound'
import DefaultLayout from './layout/Default'
import PageStoreRedirect from './pages/StoreRedirect'
import PageSample from './pages/Sample'
import LayoutPromotionSell from './pages/Promotion/sell/Layout'
import PagePromotionSellIntro from './pages/Promotion/sell/Intro'
import PagePromotionSellUrlImport from './pages/Promotion/sell/UrlImport'
import PagePromotionSellAttributeCheck from './pages/Promotion/sell/AttributeCheck'
import PagePromotionSellResult from './pages/Promotion/sell/Result'
import PageRedirectLogin from './pages/redirect/[loginType]'

function App() {
  return (
    <Routes>
      <Route element={<DefaultLayout />}>
        <Route path={'/'} element={<PageHome />} />
        <Route path={'/srd'} element={<PageStoreRedirect />} />
        <Route path={'/sample'} element={<PageSample />} />
        <Route path={'/redirect/:loginType'} element={<PageRedirectLogin />} />
        <Route element={<LayoutPromotionSell />}>
          <Route path={'/promotion/sell'} element={<PagePromotionSellIntro />} />
          <Route path={'/promotion/sell/url-import'} element={<PagePromotionSellUrlImport />} />
          <Route
            path={'/promotion/sell/attribute-check'}
            element={<PagePromotionSellAttributeCheck />}
          />
          <Route path={'/promotion/sell/result'} element={<PagePromotionSellResult />} />
        </Route>
      </Route>
      <Route path={'/terms'} element={<PageTerms />}>
        <Route path={':type'} element={<PageTerms />} />
      </Route>
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}

export default App
