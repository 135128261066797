import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { TPromotionSellActions, TPromotionSellState } from './types'

const promotionSellStore = (set): TPromotionSellState & TPromotionSellActions => ({
  importUrl: null,
  extractData: null,
  optionList: [],
  openStatusCd: null,
  marketPrice: null,
  resetData: (key) => {
    set((state) => {
      if (key) {
        state[key] = null
      } else {
        state.importUrl = null
        state.extractData = null
        state.optionList = []
        state.openStatusCd = null
        state.marketPrice = null
      }
    })
  },
  setData: (key, data) => {
    set((state) => {
      state[key] = data
    })
  },
})

export const usePromotionSellStore = create<TPromotionSellState & TPromotionSellActions>()(
  devtools(immer(promotionSellStore), { enabled: process.env.NODE_ENV !== 'production' }),
)
