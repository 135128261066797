import React, { useCallback, useEffect, useState } from 'react'
import PromotionSellHeader from '../components/Header'
import PromotionSellTitle from '../components/Title'
import { QButton } from '../../../../components/Button'
import PromotionSellImageCarousel from '../components/ImageCarousel'
import { usePopupStore } from '../../../../store/popup'
import { PromotionSellAttributeCheckButtonGroupForm } from './components/ButtonGroupForm'
import { PromotionSellAttributeCheckRadioGroupForm } from './components/RadioGroupForm'
import { QRadio } from '../../../../components/Radio'
import { getItemOpenStatusCd } from '../../../../apis/api/common'
import { TResListEnumType } from '../../../../apis/api/common/types'
import { useFieldArray, useForm } from 'react-hook-form'
import { usePromotionSellStore } from '../../../../store/promotionSell'
import {
  TAttribute,
  TAttributeValue,
  TDisplayOption,
  TExtractAttribute,
  TReqMarketPriceItemAttributeValues,
  TResProductExtract,
} from '../../../../apis/api/promotion/types'
import { getServiceItemSettingsMarketPrice } from '../../../../apis/api/promotion'
import { useNavigate } from 'react-router-dom'

type TDefaultValues = {
  openStatusCd: string
  optionList: TDisplayOption[]
}

export default function PagePromotionSellAttributeCheck(): React.JSX.Element {
  const promotionSellStore = usePromotionSellStore()
  const popupStore = usePopupStore()
  const navigate = useNavigate()
  const [isFirstLoading, setIsFirstLoading] = useState<boolean>(true)
  const [productName, setProductName] = useState<string>('')
  const [imageList, setImageList] = useState<string[]>([])
  const [openStatusCds, setOpenStatusCds] = useState<TResListEnumType[]>([])

  const defaultValues: TDefaultValues = {
    openStatusCd: 'UNOPENED',
    optionList: [],
  }

  const methods = useForm<TDefaultValues>({
    defaultValues: defaultValues,
    mode: 'all',
  })

  const { fields: fieldList } = useFieldArray({ control: methods.control, name: 'optionList' })

  const handleSubmit = useCallback(
    () => {
      methods.trigger()
      if (methods.formState.isValid) {
        popupStore.setLoading({ text: '' })
        popupStore.setLoadingVisible(true)
        const valueList = promotionSellStore.extractData.valueList
        const selectedValueIds = methods
          .getValues('optionList')
          .map((item) => item.selectedAttributeValueId)
        const selectedValueList: TReqMarketPriceItemAttributeValues[] = []
        selectedValueIds.forEach((selectedValueId) => {
          valueList.some((vItem) => {
            if (vItem.attributeValueId === selectedValueId) {
              selectedValueList.push({
                attributeId: vItem.attributeId,
                attributeValueId: vItem.attributeValueId,
              })
              return true
            }
            return false
          })
        })
        const encode = encodeURIComponent(JSON.stringify(selectedValueList))

        getServiceItemSettingsMarketPrice({
          productId: promotionSellStore?.extractData?.matchedData?.productId,
          itemAttributeValues: encode,
        })
          .then(({ context }) => {
            promotionSellStore.setData('marketPrice', context)
            promotionSellStore.setData('optionList', methods.getValues('optionList'))
            promotionSellStore.setData('openStatusCd', methods.getValues('openStatusCd'))
            popupStore.setLoadingVisible(false)
            navigate('/promotion/sell/result', { replace: false })
          })
          .catch((err) => {
            console.error(err)
            popupStore.setLoadingVisible(false)
          })

        setTimeout(() => {
          popupStore.setLoadingVisible(false)
        }, 2000)
      } else {
        // TODO. focus 로직 추가 필요
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [methods, popupStore, promotionSellStore, navigate, methods.formState.isValid],
  )

  const getExtractOpenStatusCd = (extractAttributeData: TExtractAttribute[]) => {
    let result = 'UNOPENED'
    if (extractAttributeData) {
      extractAttributeData.some((extractAttr) => {
        if (extractAttr.attributeId === 7) {
          result = extractAttr.values[0].valueId === 138 ? 'OPENED' : 'UNOPENED'
          return true
        }
        return false
      })
    }
    return result
  }
  const createOptionList = (
    optionList: TAttribute[],
    valueList: TAttributeValue[],
    openStatusCd: string,
  ) => {
    const optionsListTemp = optionList || []
    const valueListTemp = valueList || []
    const resultList = optionsListTemp
      .filter((item) => openStatusCd === 'OPENED' || item.typeCd !== 'DEPRECIATION')
      .map((item) => {
        return { ...item, values: [], selectedAttributeValueId: null }
      })

    valueListTemp.forEach((vItem) => {
      const oItemIdx = resultList.findIndex((oItem) => oItem.attributeId === vItem.attributeId)
      if (oItemIdx > -1) {
        resultList[oItemIdx].values.push(vItem)
      }
    })

    return resultList
  }

  const setExtractValue = (
    optionList: TDisplayOption[],
    extractAttributeData: TExtractAttribute[],
  ) => {
    const resultList = optionList || []
    const extractAttrList = extractAttributeData || []
    extractAttrList.forEach((extractAttr) => {
      const targetAttrId = extractAttr.attributeId
      const listIndex = optionList.findIndex((item) => {
        return item.attributeId === targetAttrId
      })
      if (listIndex > -1) {
        resultList[listIndex].selectedAttributeValueId = extractAttr.values[0].valueId
      }
    })
    return resultList
  }
  const setSelectedValue = (optionList: TDisplayOption[], originOptionList: TDisplayOption[]) => {
    const resultList = optionList || []
    if (originOptionList && originOptionList.length > 0) {
      const selectedValueInfos = originOptionList.reduce((total, item) => {
        total[item.attributeId] = item.selectedAttributeValueId
        return total
      }, {})
      resultList.forEach((item) => {
        item.selectedAttributeValueId = selectedValueInfos[item.attributeId] || null
      })
    }
    return resultList
  }

  const setOptionList = useCallback(
    (extractData: TResProductExtract, originOptionList: TDisplayOption[], isInit: boolean) => {
      let openStatusCd = methods.getValues('openStatusCd')
      if (isInit) {
        if (originOptionList.length > 0) {
          openStatusCd = promotionSellStore.openStatusCd || methods.getValues('openStatusCd')
        } else {
          openStatusCd = getExtractOpenStatusCd(extractData?.extractAttributeData)
        }
      }

      /* 옵션 리스트 생성*/
      const optionList = createOptionList(
        extractData.attributeList,
        extractData.valueList,
        openStatusCd,
      )

      /* 옵션 하위 값 추가 */
      if (isInit) {
        if (originOptionList.length > 0) {
          setSelectedValue(optionList, originOptionList)
        } else {
          setExtractValue(optionList, extractData?.extractAttributeData)
        }
      } else {
        setSelectedValue(optionList, originOptionList)
      }

      methods.setValue('optionList', optionList)
      methods.setValue('openStatusCd', openStatusCd)
      methods.reset(methods.getValues())
    },
    [methods, promotionSellStore.openStatusCd],
  )

  const getOpenStatusCds = () => {
    getItemOpenStatusCd()
      .then(({ context }) => {
        setOpenStatusCds(context)
      })
      .catch((e) => {
        console.error(e)
      })
  }

  useEffect(
    () => {
      popupStore.setLoadingVisible(true)
      getOpenStatusCds()
      if (promotionSellStore.extractData) {
        setProductName(promotionSellStore.extractData.parsedInfo?.title)
        setImageList(
          promotionSellStore.extractData.parsedInfo?.imageData.map((img) => img.imageUrl) || [],
        )
        setOptionList(promotionSellStore.extractData, promotionSellStore.optionList, true)
      }
      setIsFirstLoading(false)
      popupStore.setLoadingVisible(false)
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useEffect(
    () => {
      if (!isFirstLoading) {
        setOptionList(promotionSellStore.extractData, methods.getValues('optionList'), false)
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [methods.watch('openStatusCd')],
  )

  return (
    <>
      <PromotionSellHeader />
      <div className={'px-[32px] py-[48px] inline-flex flex-col justify-between flex-1'}>
        <PromotionSellTitle>
          틀린 정보가 있다면
          <br />
          수정해 주세요
        </PromotionSellTitle>
        <div className={'flex-1 inline-flex flex-col justify-between mt-[48px]'}>
          <div>
            {/* 제품명 */}
            <div>
              <div className={'text-[white] text-[18px] font-semibold leading-[1.5] mb-[12px]'}>
                제품명
              </div>
              <div
                className={
                  'bg-[#272F3E] inline-block text-white text-[16px] leading-[1.5] px-[20px] py-[12px] rounded-[24px]'
                }
              >
                {productName || '-'}
              </div>
            </div>
            {/* 이미지 */}
            {imageList.length > 0 && (
              <div className={'mt-[24px] mb-[32px]'}>
                <PromotionSellImageCarousel list={imageList} />
              </div>
            )}

            {/* 개봉 여부 */}
            <div className={'mt-[24px] max-w-[80%]'}>
              <div className={'text-[white] text-[18px] font-semibold leading-[1.5] mb-[12px]'}>
                개봉 여부
              </div>
              <div className={'w-full flex'}>
                {openStatusCds.map((openStatusCd) => (
                  <QRadio
                    key={openStatusCd.code}
                    name={'unboxing'}
                    value={openStatusCd.code}
                    type={'button'}
                    defaultValue={methods.getValues('openStatusCd')}
                    onChange={(val) => {
                      methods.setValue('openStatusCd', val)
                      methods.reset(methods.getValues())
                    }}
                  >
                    <QRadio.ButtonLabel>{openStatusCd.value}</QRadio.ButtonLabel>
                  </QRadio>
                ))}
              </div>
            </div>

            {fieldList.length > 0 &&
              fieldList.map((item, idx) => {
                const valuesLength = item.values.length
                if (valuesLength === 2) {
                  return (
                    <div className={'mt-[24px] max-w-[80%]'} key={item.attributeId}>
                      <PromotionSellAttributeCheckButtonGroupForm
                        idx={idx}
                        list={item.values}
                        title={item.name}
                        methods={methods}
                      />
                    </div>
                  )
                } else {
                  return (
                    <div className={'mt-[24px]'} key={item.attributeId}>
                      <PromotionSellAttributeCheckRadioGroupForm
                        idx={idx}
                        list={item.values}
                        title={item.name}
                        methods={methods}
                      />
                    </div>
                  )
                }
              })}
          </div>
          <QButton
            addClass={'mt-[48px] inline-flex items-center justify-center'}
            onClick={handleSubmit}
          >
            <span className={'text-[18px] font-semibold'}>즉시 판매 금액 확인하기</span>
          </QButton>
        </div>
      </div>
    </>
  )
}
