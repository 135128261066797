import Dialog from '@mui/material/Dialog'
import { usePopupStore } from '../../store/popup'
import { CircularProgress } from '@mui/material'

export default function LoadingDialog() {
  const popupStore = usePopupStore()

  const close = () => {
    popupStore.setLoadingVisible(false)
  }

  return (
    <Dialog
      open={popupStore.isLoading}
      fullScreen
      classes={{
        paper: '!bg-[rgba(0,0,0,0.5)] justify-center items-center',
      }}
      onClose={close}
    >
      <div className={`flex flex-col items-center justify-center`}>
        <CircularProgress style={{ color: 'white' }} />
        {popupStore.loading.text && (
          <div className={'mt-[28px] text-[white] text-[16px] leading-[1.5]'}>
            {typeof popupStore.loading.text === 'string'
              ? popupStore.loading.text
              : popupStore.loading.text()}
          </div>
        )}
      </div>
    </Dialog>
  )
}
