import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Autoplay, Controller } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'

export default function LandingBenefitSection({
  isDesktop,
}: {
  isDesktop: boolean
}): React.JSX.Element {
  const swiperOneList = [
    { id: '1-1', url: require('../../../assets/img/landing/bubble/bubble_product_01.png') },
    { id: '1-2', url: require('../../../assets/img/landing/bubble/bubble_product_05.png') },
    { id: '1-3', url: require('../../../assets/img/landing/bubble/bubble_product_04.png') },
    { id: '1-4', url: require('../../../assets/img/landing/bubble/bubble_product_03.png') },
    { id: '1-5', url: require('../../../assets/img/landing/bubble/bubble_product_02.png') },
    { id: '1-6', url: require('../../../assets/img/landing/bubble/bubble_product_01.png') },
    { id: '1-7', url: require('../../../assets/img/landing/bubble/bubble_product_05.png') },
    { id: '1-8', url: require('../../../assets/img/landing/bubble/bubble_product_04.png') },
    { id: '1-9', url: require('../../../assets/img/landing/bubble/bubble_product_03.png') },
    { id: '1-10', url: require('../../../assets/img/landing/bubble/bubble_product_02.png') },
  ]
  const swiperTwoList = [
    { id: '2-1', url: require('../../../assets/img/landing/bubble/box_product_05.png') },
    { id: '2-2', url: require('../../../assets/img/landing/bubble/box_product_04.png') },
    { id: '2-3', url: require('../../../assets/img/landing/bubble/box_product_03.png') },
    { id: '2-4', url: require('../../../assets/img/landing/bubble/box_product_02.png') },
    { id: '2-5', url: require('../../../assets/img/landing/bubble/box_product_01.png') },
    { id: '2-6', url: require('../../../assets/img/landing/bubble/box_product_05.png') },
    { id: '2-7', url: require('../../../assets/img/landing/bubble/box_product_04.png') },
    { id: '2-8', url: require('../../../assets/img/landing/bubble/box_product_03.png') },
    { id: '2-9', url: require('../../../assets/img/landing/bubble/box_product_02.png') },
    { id: '2-10', url: require('../../../assets/img/landing/bubble/box_product_01.png') },
  ]

  const swiperThreeList = [
    { id: '3-1', url: require('../../../assets/img/landing/bubble/bubble_money.png') },
    { id: '3-2', url: require('../../../assets/img/landing/bubble/bubble_money.png') },
    { id: '3-3', url: require('../../../assets/img/landing/bubble/bubble_money.png') },
    { id: '3-4', url: require('../../../assets/img/landing/bubble/bubble_money.png') },
    { id: '3-5', url: require('../../../assets/img/landing/bubble/bubble_money.png') },
    { id: '3-6', url: require('../../../assets/img/landing/bubble/bubble_money.png') },
    { id: '3-7', url: require('../../../assets/img/landing/bubble/bubble_money.png') },
    { id: '3-8', url: require('../../../assets/img/landing/bubble/bubble_money.png') },
    { id: '3-9', url: require('../../../assets/img/landing/bubble/bubble_money.png') },
    { id: '3-10', url: require('../../../assets/img/landing/bubble/bubble_money.png') },
  ]

  const swiperOptions = {
    allowTouchMove: false,
    centeredSlides: false,
    slidesPerView: 5,
    spaceBetween: 20,
    loop: true,
    modules: [Autoplay, Controller],
  }

  const swiperOptions2 = {
    allowTouchMove: false,
    centeredSlides: false,
    slidesPerView: 5,
    spaceBetween: 0,
    loop: true,
    modules: [Autoplay, Controller],
  }

  const sectionRef = useRef(null)
  const firstSwiperRef = useRef(null)
  const thirdSwiperRef = useRef(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [firstSwiper, setFirstSwiper] = useState(null)
  const [secondSwiper, setSecondSwiper] = useState(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [thirdSwiper, setThirdSwiper] = useState(null)

  const initSwiper = useCallback(async ([entry], observer) => {
    if (!entry.isIntersecting) {
      return
    }
    firstSwiperRef.current?.slideTo(0)
    thirdSwiperRef.current?.slideTo(0)
    observer.unobserve(entry.target) // 함수가 실행될 때, 관찰을 끝내기.
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver(initSwiper, { threshold: 0.1 })
    observer.observe(sectionRef.current)
    return () => {
      observer.disconnect()
    }
  }, [initSwiper])

  useEffect(() => {
    firstSwiperRef.current?.slideTo(0)
    thirdSwiperRef.current?.slideTo(0)
  }, [isDesktop])

  return (
    <section ref={sectionRef} className={'bg-[#394150]'}>
      <article
        className={
          'landing-container flex flex-col justify-center items-center py-[80px] tablet:pt-[160px] tablet:pb-0 max-w-none'
        }
      >
        <h2
          className={
            'text-white text-[28px] tablet:text-[48px] font-[700] leading-[1.4] tracking-[-0.07px] text-center transition-all'
          }
        >
          퀵셀로 팔면
          <br />
          신청하는 즉시
          <br className={'tablet:hidden'} /> 현금 입금!
        </h2>
        <div
          className={`relative w-full max-w-[700px] h-[580px] tablet:max-w-none tablet:h-[680px] flex flex-col tablet:flex-row mt-[28px] tablet:mt-[80px] items-center justify-center tablet:items-start gap-[24px] overflow-hidden`}
        >
          <div
            className={
              'relative flex w-[1496px] h-full tablet:w-full tablet:h-[520px] flex-shrink-0'
            }
          >
            <div
              className={
                'absolute flex flex-col tablet:flex-row bottom-0 tablet:bottom-1/2 translate-y-0 right-1/2 tablet:right-0 translate-x-[50%] tablet:translate-x-0 tablet:translate-y-[50%] gap-[24px]'
              }
            >
              <Swiper
                className={'relative w-[120px] h-[700px] tablet:w-[1500px] tablet:h-[280px]'}
                {...swiperOptions}
                autoplay={{
                  delay: 2000,
                  reverseDirection: true,
                  disableOnInteraction: false,
                }}
                direction={isDesktop ? 'horizontal' : 'vertical'}
                onInit={(swiper) => {
                  firstSwiperRef.current = swiper
                }}
                onSwiper={setFirstSwiper}
                controller={{ control: secondSwiper }}
              >
                {swiperOneList.map((item) => (
                  <SwiperSlide key={item.id}>
                    <div className={'w-[120px] h-[120px] tablet:w-[280px] tablet:h-[280px]'}>
                      <img src={item.url} alt={''} />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
          <div
            className={
              'relative w-[200px] h-[200px] tablet:w-[520px] tablet:h-[520px] bg-[#272F3E] rounded-[16px] overflow-hidden flex-shrink-0 flex flex-wrap justify-center items-center gap-[24px]'
            }
            style={{ boxShadow: '0px 80px 120px 0px rgba(0, 0, 0, 0.25)' }}
          >
            <div
              className={
                'absolute flex flex-col tablet:flex-row bottom-0 tablet:bottom-1/2 translate-y-0 right-1/2 tablet:right-0 translate-x-[50%] tablet:translate-x-0 tablet:translate-y-[50%] gap-[24px]'
              }
            >
              <Swiper
                className={'relative w-[200px] h-[1000px] tablet:w-[2600px] tablet:h-[520px]'}
                {...swiperOptions2}
                direction={isDesktop ? 'horizontal' : 'vertical'}
                onSwiper={setSecondSwiper}
                controller={{ control: firstSwiper }}
              >
                {swiperTwoList.map((item) => (
                  <SwiperSlide key={item.id}>
                    <div
                      className={
                        'w-[200px] tablet:w-[520px] flex items-center justify-center h-full'
                      }
                    >
                      <div className={'w-[160px] tablet:w-[400px]'}>
                        <img src={item.url} alt={''} />
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
          <div
            className={
              'relative flex w-[1496px] h-full tablet:w-full tablet:h-[520px] flex-shrink-0'
            }
          >
            <div
              className={
                'absolute flex flex-col tablet:flex-row top-0 tablet:top-1/2 translate-y-0 left-1/2 tablet:left-0 translate-x-[-50%] tablet:translate-x-0 tablet:translate-y-[-50%] gap-[24px]'
              }
            >
              <Swiper
                className={'relative w-[120px] h-[700px] tablet:w-[1500px] tablet:h-[280px]'}
                {...swiperOptions}
                autoplay={{
                  delay: 2000,
                  reverseDirection: true,
                  disableOnInteraction: false,
                }}
                direction={isDesktop ? 'horizontal' : 'vertical'}
                onInit={(swiper) => {
                  thirdSwiperRef.current = swiper
                }}
                onSwiper={setThirdSwiper}
                controller={{ control: thirdSwiper }}
              >
                {swiperThreeList.map((item) => (
                  <SwiperSlide key={item.id}>
                    <div className={'w-[120px] h-[120px] tablet:w-[280px] tablet:h-[280px]'}>
                      <img src={item.url} alt={''} />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </article>
    </section>
  )
}
