import { useEffect, useState } from 'react'

type sizeProps = {
  width: number | undefined
  height: number | undefined
}

export default function useWindowResize() {
  const [isDesktop, setIsDesktop] = useState<boolean>(false)
  const [windowSize, setWindowSize] = useState<sizeProps>({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (windowSize.width < 1024) {
      setIsDesktop(false)
    } else {
      setIsDesktop(true)
    }
  }, [windowSize.width])

  return {
    windowSize,
    isDesktop,
  }
}
