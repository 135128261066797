import React from 'react'

export default function CommonFooter(): React.JSX.Element {
  const pClass =
    'inline-block  text-[12px] tablet:text-[14px] leading-[1.5] text-[#999999] my-[8px] transition-all'
  const divideClass = 'after:content-["|"] after:text-[#dddddd] after:mx-[8px]'
  return (
    <footer className={'bg-[#f5f5f5] flex flex-col flex-wrap items-center p-[20px]'}>
      <div className="text-center pt-[30px] max-w-[960px]">
        <p className={[pClass, divideClass].join(' ')}>상호 : 주식회사 비엘큐</p>
        <p className={[pClass, divideClass].join(' ')}>대표 : 홍솔</p>
        <p className={[pClass, divideClass].join(' ')}>개인정보책임자 : 이희석</p>
        <p className={[pClass, divideClass].join(' ')}>사업자등록번호 : 557-88-01371</p>
        <p className={[pClass, divideClass].join(' ')}>통신판매업 신고번호 : 2022-서울강남-01638</p>
        <p className={[pClass, divideClass].join(' ')}>
          <a
            className={'underline decoration-1 underline-offset-2 decoration-black  text-[#000]'}
            href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=5578801371"
            target="_blank"
            rel="noreferrer"
          >
            사업자정보확인
          </a>
        </p>
        <p className={[pClass, divideClass].join(' ')}>
          본사 주소 : 서울특별시 성동구 성수이로22길 43, 금오빌딩 5층
        </p>
        <p className={[pClass, divideClass].join(' ')}>
          대표이메일 : quicksell@testvalleykorea.com
        </p>
        <p className={pClass}>대표전화 : 1800-9943</p>
      </div>
      <div className="max-w-[980px] text-[10px] tablet:text-[12px] leading-1 text-[#bdbdbd] pt-[30px] transition-all">
        COPYRIGHT © 주식회사 비엘큐 inc. All RIGHTS RESERVED.
      </div>
    </footer>
  )
}
