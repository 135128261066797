import React, { useCallback, useEffect, useState } from 'react'
import shareImg from '../../../assets/icon/share.svg'

import { StyledEngineProvider } from '@mui/material/styles'
import ShareModal from '../components/ShareModal/index'
import { onClickCopyURL, onClickFacebookShare, onClickMsgShare } from '../../../utils/common'
import { toast, toastClearWaitingQueue } from '../../../components/Toast'
import useKaKao from '../../../utils/hooks/kakao'

export type TShareIcon = {
  id: string
  imgSrcPc: any
  imgSrcMo: any
  text: string
  onClick?: () => void
}

export default function LandingHeader({ isDesktop }: { isDesktop: boolean }): React.JSX.Element {
  const [isShare, setIsShare] = useState(false)
  const [open, setOpen] = useState(false)

  const handleOnCLickCopyUrL = useCallback(() => {
    onClickCopyURL()
    toastClearWaitingQueue()
    toast('URL 복사가 완료되었어요!').green()
  }, [])

  const { handleClickKakaoShare } = useKaKao({})

  const shareItems: TShareIcon[] = [
    {
      id: 'kakaotalk',
      imgSrcPc: require('../../../assets/icon/kakaotalk_pc.png'),
      imgSrcMo: require('../../../assets/icon/kakaotalk_mo.png'),
      text: '카카오톡',
      onClick: handleClickKakaoShare,
    },
    {
      id: 'facebook',
      imgSrcPc: require('../../../assets/icon/facebook_pc.png'),
      imgSrcMo: require('../../../assets/icon/facebook_mo.png'),
      text: '페이스북',
      onClick: onClickFacebookShare,
    },
    {
      id: 'message',
      imgSrcPc: require('../../../assets/icon/message_pc.png'),
      imgSrcMo: require('../../../assets/icon/message_mo.png'),
      text: '메세지',
      onClick: onClickMsgShare,
    },
    {
      id: 'url',
      imgSrcPc: require('../../../assets/icon/url_pc.png'),
      imgSrcMo: require('../../../assets/icon/url_mo.png'),
      text: 'URL 복사',
      onClick: handleOnCLickCopyUrL,
    },
  ]

  const handleClickShare = () => {
    if (isDesktop) {
      setIsShare(!isShare)
    } else {
      setOpen(!open)
    }
  }

  useEffect(
    () => {
      if (isDesktop) {
        if (open) {
          setIsShare(true)
        }
        setOpen(false)
      } else {
        if (isShare) {
          setOpen(true)
        }
        setIsShare(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDesktop],
  )

  return (
    <header
      className={`w-full absolute top-0 inset-x-0 h-[64px] tablet:h-[104px] bg-transparent text-white mt-[44px] tablet:mt-0`}
    >
      <div className={'landing-container flex justify-between items-center'}>
        <div className={'w-[64px] tablet:w-[90px]'}>
          <img
            className={'w-full h-auto'}
            src={require('../../../assets/logo_text.png')}
            alt={'quicksell logo'}
          />
        </div>

        <div className={'relative'}>
          <button className={'w-[24px]'} onClick={handleClickShare}>
            <img className={'w-full h-auto'} src={shareImg} alt={'share'} />
          </button>
          <StyledEngineProvider injectFirst>
            <ShareModal open={open} setOpen={setOpen} shareIcons={shareItems} />
          </StyledEngineProvider>
          {isShare && (
            <div
              className={
                'w-[352px] absolute bg-[#394150] top-10 right-0 rounded-[24px] py-[32px] text-[12px] justify-evenly hidden mobile:flex'
              }
            >
              {shareItems.map((item) => (
                <div
                  role="presentation"
                  key={item.id}
                  className={`flex flex-col items-center ${item.onClick ? 'cursor-pointer' : ''}`}
                  onClick={item.onClick}
                >
                  <img className={'mb-[8px] h-[48px]'} src={item.imgSrcPc} alt={item.id} />
                  {item.text}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </header>
  )
}
