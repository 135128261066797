import React from 'react'

export default function LandingProcessSection(): React.JSX.Element {
  const data = [
    { id: 1, text: '상품을 검색하여 시세를 확인해요' },
    { id: 2, text: '퀵셀인증 판매를 선택해요' },
    { id: 3, text: '판매 등록을 완료해요' },
    { id: 4, text: '바로 입금된 금액을 확인해요' },
    { id: 5, text: '상품을 문 밖에 두면 끝!' },
  ]

  return (
    <section className={'bg-white'}>
      <article
        className={'landing-container flex justify-center items-center py-[80px] tablet:py-[160px]'}
      >
        <div className={'text-black text-center'}>
          <h2
            className={
              'text-[28px] tablet:text-[48px] font-[700] leading-[1.4] tracking-[-0.07px] tablet:tracking-[-0.12px] text-[#272F3E] transition-all'
            }
          >
            퀵셀의 판매 과정을
            <br />
            간단하게 알아볼까요?
          </h2>
          <div className={'mt-[40px] flex flex-col gap-[40px] max-w-[786px]'}>
            {data.map((item) => (
              <div key={item.id}>
                <img
                  src={require(`../../../assets/img/landing/process/process_0${item.id}.png`)}
                  alt={item.text}
                />
                <p
                  className={
                    'text-[16px] tablet:text-[28px] font-[600] leading-[1.5] mt-[16px] tablet:mt-[40px] tablet:tracking-[-0.07px] text-[#394150] transition-all'
                  }
                >{`${item.id}. ${item.text}`}</p>
              </div>
            ))}
          </div>
        </div>
      </article>
    </section>
  )
}
