import React, { PropsWithChildren } from 'react'

type TPromotionSellTitle = {
  addClass?: string
}

export default function PromotionSellTitle({
  children,
  ...props
}: PropsWithChildren<TPromotionSellTitle>): React.JSX.Element {
  return (
    <h1
      className={[
        'text-white text-[32px] font-semibold -tracking-[0.08px] leading-[140%] fold:text-[26px] transition-all',
        props.addClass,
      ].join(' ')}
    >
      {children}
    </h1>
  )
}
