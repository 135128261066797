import Dialog from '@mui/material/Dialog'
import React, { Dispatch, SetStateAction, useCallback } from 'react'

type TPromotionSellQrDialogProps = {
  open?: boolean
  setOpen?: Dispatch<SetStateAction<boolean>>
}
export default function PromotionSelResultQrDialog({ open, setOpen }: TPromotionSellQrDialogProps) {
  const handleClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  return (
    <Dialog
      open={open}
      scroll={'paper'}
      fullWidth={true}
      onClose={handleClose}
      classes={{
        root: '',
        paper: `!rounded-[24px] !m-0 !w-auto !max-w-[280px] !bg-[#272F3E]`,
        container: 'min-w-[280px]',
      }}
    >
      <div className={'pt-[40px] px-[36px] pb-[64px] flex flex-col justify-center items-center'}>
        <img
          className={'w-[96px] none-drag'}
          src={require(`../../../../../assets/logo.png`)}
          alt=""
        />
        <div
          className={
            'text-white text-[18px] font-bold text-center leading-[1.5] tracking-[-0.045px]'
          }
        >
          가장 빠른 전자제품 중고거래, <br /> <span className={'text-[#29A5FF]'}>퀵셀</span>에서
          판매 후 <br />
          즉시 입금 받아보세요
        </div>
        <div className={'w-[140px] h-[140px] text-black mt-[32px]'}>
          <img
            className={'w-full'}
            src={require(`../../../../../assets/img/landing/mainBanner/qrcode.png`)}
            alt="qrcode"
          />
        </div>
      </div>
    </Dialog>
  )
}
